import React, { useEffect, useContext, useState } from "react";
import { produtos } from "./JsonProdutosDry";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Title from '../Title'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
const ForDogsData = ({ amount }) => {

  // const “ = JSON.parse(localStorage.getItem("products"));
  const { shops, setShops, productDots, serverProd, produtosSelecionados, opt, tabs, setTabs, tabs4, setTabs4, tabs2, setTabs2, tabs3, setTabs3, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
  const navigate = useNavigate()
  const shops2 = shops.filter(pr => pr.showed)

  const docgs1 = serverProd.filter((prod) => ((prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed))
  // console.log(docgs1)
  const docgs = docgs1
    .sort((a, b) => {
      // Сначала сортируем по условию scladqty > 0
      let maximumA = 0
      let maximumB = 0
      for (let i = 0; i < shops2.length; i++) {
        const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
        const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
        if (scladqtyA) {
          maximumA = maximumA + scladqtyA.scladqty
          // console.log(shops2[i], scladqtyA)
        }
        if (scladqtyB) {
          maximumB = maximumB + scladqtyB.scladqty
          // console.log(shops2[i], scladqtyB)
        }
      }
      if (maximumA > 0 && maximumB === 0) return -1;
      if (maximumA === 0 && maximumB > 0) return 1;

      // Если оба объекта в одной группе, сортируем по name
      return a.name.localeCompare(b.name);
    });
  console.log(docgs1.filter(p => p.kategory == 'Уход'))
  // const cats = serverProd.filter((prod) => (prod.forwho == "Для кошек" || prod.forwho == "Для кошек и собак") && prod.brand != 'KotoPes')
  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const changeItensCart = (id) => {
    const produtos = serverProd

    const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
    const produtoDados = produtos.find((produto) => produto.id == +id);



    const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  const drydey = serverProd.filter((prod) => (
    // prod.forwho == "Для собак" && 
    prod.kategory.includes("сухой корм") && prod.forwho == "Для собак") && prod.showed).sort((a, b) => {
      // Сначала сортируем по условию scladqty > 0
      let maximumA = 0
      let maximumB = 0
      for (let i = 0; i < shops2.length; i++) {
        const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
        const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
        if (scladqtyA) {
          maximumA = maximumA + scladqtyA.scladqty
          // console.log(shops2[i], scladqtyA)
        }
        if (scladqtyB) {
          maximumB = maximumB + scladqtyB.scladqty
          // console.log(shops2[i], scladqtyB)
        }
      }
      if (maximumA > 0 && maximumB === 0) return -1;
      if (maximumA === 0 && maximumB > 0) return 1;

      // Если оба объекта в одной группе, сортируем по name
      return a.name.localeCompare(b.name);
    });
  const yhod = serverProd.filter((prod) => prod.podkategory == "Пеленки" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const yhodother = serverProd.filter((prod) => prod.podkategory == "Другое" && prod.kategory == "Уход" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const shampoodog = serverProd.filter((prod) => prod.podkategory == "Шампунь" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const aptekadog = serverProd.filter((prod) => prod.kategory == "ВетАптека" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const toys = serverProd.filter((prod) => prod.podkategory == "Игрушки" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const amunation = serverProd.filter((prod) => prod.podkategory == "Амуниция" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const houses = serverProd.filter((prod) => prod.podkategory == "Лежанки/Домики" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак") && prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  // const shampoocat = serverProd.filter((prod) => prod.podkategory == "Шампунь" && (prod.forwho == "Для кошек" || prod.forwho == "Для кошек и собак"))
  // const vccat = serverProd.filter((prod) => prod.podkategory == "Наполнитель" && (prod.forwho == "Для кошек" || prod.forwho == "Для кошек и собак"))
  // const aptekablohi = apteka.filter((prod) => prod.podkategory == "От блох и клещей")
  // console.log(serverProd, serverProd)
  const GoInfo = (imageId, name) => {
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd: serverProd,

        produtosSelecionados: produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/fordogs"
      }
    });
  };

  // let tt = photo[0]
  // if(!photo){
  //   tt = ''
  // }
  // console.log(drydey)
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex2, setSelectedIndex2] = useState(0);
  const [selectedIndex3, setSelectedIndex3] = useState(0);
  const [selectedIndex4, setSelectedIndex4] = useState(0);
  useEffect(() => {
    if (tabs == 'сухой корм') {
      setSelectedIndex(1)
    }
    if (tabs == 'Уход') {
      setSelectedIndex(2)
    }

    if (tabs == 'ВетАптека') {
      setSelectedIndex(3)
    }
    if (tabs == 'Аксессуары') {
      setSelectedIndex(4)
    }
    if (tabs2 == 'Игрушки') {
      setSelectedIndex2(1)
    }

    if (tabs2 == 'Амуниция') {
      setSelectedIndex2(2)
    }
    if (tabs2 == 'Лежанки/Домики') {
      setSelectedIndex2(3)
    }

    if (tabs3 == 'Шампуни') {
      setSelectedIndex3(1)
    }
    if (tabs3 == 'Пеленки') {
      setSelectedIndex3(2)
    }
    if (tabs3 == 'Другое') {
      setSelectedIndex3(3)
    }

    if (tabs4 == 'Средства от глистов') {
      setSelectedIndex4(0)
    }
    if (tabs4 == 'Средства от блох и клещей') {
      setSelectedIndex4(1)
    }
    if (tabs4 == 'Успокоительные средства') {
      setSelectedIndex4(2)
    }
    if (tabs4 == 'Уход за глазами') {
      setSelectedIndex4(3)
    }
    if (tabs4 == 'Витамины и добавки') {
      setSelectedIndex4(4)
    }
    if (tabs4 == 'Капли ушные') {
      setSelectedIndex4(5)
    }

  }, [tabs, tabs2, tabs3,tabs4])

  const handleSelect2 = (index) => {
    // console.log(index)
    if (index == 1) {
      setTabs2('Игрушки')
    }
    if (index == 2) {
      setTabs2('Амуниция')
    }
    if (index == 3) {
      setTabs2('Лежанки/Домики')
    }



    setSelectedIndex2(index);
  };
  const handleSelect3 = (index) => {
    // console.log(index)
    if (index == 1) {
      setTabs3('Шампуни')
    }
    if (index == 2) {
      setTabs3('Пеленки')
    }
    if (index == 3) {
      setTabs3('Другое')
    }
    setSelectedIndex3(index);

  }
    const handleSelect4 = (index) => {
      console.log(index)
      if (index == 0) {
        setTabs4('Средства от глистов')
      }
      if (index == 1) {
        setTabs4('Средства от блох и клещей')
      }
      if (index == 2) {
        setTabs4('Успокоительные средства')
      }
      if (index == 3) {
        setTabs4('Уход за глазами')
      }
      if (index == 4) {
        setTabs4('Витамины и добавки')
      }
      if (index == 5) {
        setTabs4('Капли ушные')
      }



    setSelectedIndex4(index);
  };
  const handleSelect = (index) => {
    if (index == 1) {
      setTabs('сухой корм')
    }
    if (index == 2) {
      setTabs('Уход')
    }

    if (index == 3) {
      setTabs('ВетАптека')
    }
    if (index == 4) {
      setTabs('Аксессуары')
    }
    if (index == 0) {
      setTabs('все товары')
    }
    setSelectedIndex(index);
  };
  return (
    <>



      <Tabs selectedIndex={selectedIndex} onSelect={handleSelect} >
        <TabList style={{
          background: 'none',

          borderRadius: '25px',
          textAlign: 'center',
          display: 'flex',
          position: 'initial',
          margin: '15px',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-evenly'
        }}>

          <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Все Товары</b></Tab>
          <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Сухой Корм</b></Tab>
          <Tab style={{
            fontSize: '20px',

            margin: '15px',
            position: 'initial',
            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid #0000',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Уход</b></Tab>

          <Tab style={{
            fontSize: '20px',

            margin: '15px',
            position: 'initial',
            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid #0000',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>ВетАптека</b></Tab>
          <Tab style={{
            fontSize: '20px',

            margin: '15px',
            position: 'initial',
            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid #0000',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Аксессуары</b></Tab>

        </TabList>
        <TabPanel>
          <br />
          <div className={style.produtos_container}>
            {!opt.auth && docgs.map(
              ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                let maximum2 = 0
                for (let i = 0; i < shops2.length; i++) {
                  const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                  if (scladqty) {
                    maximum2 = maximum2 + scladqty.scladqty
                    // console.log(shops2[i], scladqty)
                  }
                }
                let tt = ''
                if (photo[0]) {
                  tt = photo[0]
                }
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                return index <= amount && (
                  <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                    {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                    {/* <img id={id} className={style.img} src={tt} /> */}
                    <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                      <LazyLoadImage
                        id={id}
                        className={style.img}
                        src={tt}
                        alt="Example Image"
                        height="100%"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                    </div>
                    {/* </div> */}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    {shops2.length > 0 && (shops2.map(pr => {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                      // console.log(productDots, scladqty)
                      return <td className={style.tabletd}>

                        <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                      </td>
                    }))}
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(maximum2) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(maximum2) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(maximum2) > 0 && (<>


                        <div className={style.doublexoom}>
                          <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                        </div>
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(maximum2) > 0 && (<>
                        <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                        <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
                  </div>
                )
              }
            )}

            <br />
          </div>
        </TabPanel>
        <TabPanel>
          <br />
          <div className={style.produtos_container}>
            {!opt.auth && drydey.map(
              ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                let maximum2 = 0
                for (let i = 0; i < shops2.length; i++) {
                  const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                  if (scladqty) {
                    maximum2 = maximum2 + scladqty.scladqty
                    // console.log(shops2[i], scladqty)
                  }
                }
                let tt = ''
                if (photo[0]) {
                  tt = photo[0]
                }
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                return index <= amount && (
                  <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                    {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                    {/* <img id={id} className={style.img} src={tt} /> */}
                    <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                      <LazyLoadImage
                        id={id}
                        className={style.img}
                        src={tt}
                        alt="Example Image"
                        height="100%"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                    </div>
                    {/* </div> */}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    {shops2.length > 0 && (shops2.map(pr => {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                      // console.log(productDots, scladqty)
                      return <td className={style.tabletd}>

                        <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                      </td>
                    }))}
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(maximum2) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(maximum2) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(maximum2) > 0 && (<>


                        <div className={style.doublexoom}>
                          <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                        </div>
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(maximum2) > 0 && (<>
                        <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                        <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
                  </div>
                )
              }
            )}

            <br />
          </div>
        </TabPanel>
        <TabPanel>

          <Tabs forceRenderTabPanel selectedIndex={selectedIndex3} onSelect={handleSelect3} >
            <TabList style={{
              background: 'none',

              borderRadius: '25px',
              textAlign: 'center',
              display: 'flex',
              position: 'initial',
              margin: '15px',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-evenly'
            }}>
              <Tab style={{
                margin: '15px',
                position: 'initial',
                borderBottom: '1px solid #0000',
                fontSize: '14px',

                borderRadius: '25px',
                textAlign: 'center',
                display: 'flex',
                borderRadius: '25px',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}><b>Шампуни</b>
              </Tab>
              <Tab style={{
                margin: '15px',
                position: 'initial',
                borderBottom: '1px solid #0000',
                fontSize: '14px',

                borderRadius: '25px',
                textAlign: 'center',
                display: 'flex',
                borderRadius: '25px',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}><b>Пеленки</b>
              </Tab>
              <Tab style={{
                margin: '15px',
                position: 'initial',
                borderBottom: '1px solid #0000',
                fontSize: '14px',

                borderRadius: '25px',
                textAlign: 'center',
                display: 'flex',
                borderRadius: '25px',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}><b>Другое</b>
              </Tab>
            </TabList>
            <TabPanel>
              <br />
              <div className={style.produtos_container}>
                {!opt.auth && shampoodog.map(
                  ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                    let maximum2 = 0
                    for (let i = 0; i < shops2.length; i++) {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                      if (scladqty) {
                        maximum2 = maximum2 + scladqty.scladqty
                        // console.log(shops2[i], scladqty)
                      }
                    }
                    let tt = ''
                    if (photo[0]) {
                      tt = photo[0]
                    }
                    const cart = produtosSelecionados.find((produto) => produto.id == +id);
                    let cartb = false
                    if (cart) {
                      cartb = true
                    }
                    return index <= amount && (
                      <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                        {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                        {/* <img id={id} className={style.img} src={tt} /> */}
                        <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                          <LazyLoadImage
                            id={id}
                            className={style.img}
                            src={tt}
                            alt="Example Image"
                            height="100%"
                            effect="blur"
                          // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                          // width="600px"
                          />
                        </div>
                        {/* </div> */}
                        <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                          </td>
                        }))}
                        <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                          {forwho == 'Для собак' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐶<span></span>
                            </div>
                          )}
                          {forwho == 'Для кошек' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐱<span></span>
                            </div>
                          )}
                          {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              ХИТ
                              <span></span>
                            </div>
                          )}
                          {Number(maximum2) > 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {price}RUB
                              <span></span>
                            </div>}
                          {Number(maximum2) <= 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              Распродано!
                              <span></span>
                            </div>}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                          {!cartb && Number(maximum2) > 0 && (<>


                            <div className={style.doublexoom}>
                              <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                            </div>
                            <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                          </>
                          )}
                          {cartb && Number(maximum2) > 0 && (<>
                            <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                            <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                          </>
                          )}
                        </div>
                      </div>
                    )
                  }
                )}

                <br />
              </div>

            </TabPanel>
            <TabPanel>
              <br />
              <div className={style.produtos_container}>
                {!opt.auth && yhod.map(
                  ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                    let maximum2 = 0
                    for (let i = 0; i < shops2.length; i++) {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                      if (scladqty) {
                        maximum2 = maximum2 + scladqty.scladqty
                        // console.log(shops2[i], scladqty)
                      }
                    }
                    let tt = ''
                    if (photo[0]) {
                      tt = photo[0]
                    }
                    const cart = produtosSelecionados.find((produto) => produto.id == +id);
                    let cartb = false
                    if (cart) {
                      cartb = true
                    }
                    return index <= amount && (
                      <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                        {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                        {/* <img id={id} className={style.img} src={tt} /> */}
                        <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                          <LazyLoadImage
                            id={id}
                            className={style.img}
                            src={tt}
                            alt="Example Image"
                            height="100%"
                            effect="blur"
                          // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                          // width="600px"
                          />
                        </div>
                        {/* </div> */}
                        <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                          </td>
                        }))}
                        <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                          {forwho == 'Для собак' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐶<span></span>
                            </div>
                          )}
                          {forwho == 'Для кошек' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐱<span></span>
                            </div>
                          )}
                          {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              ХИТ
                              <span></span>
                            </div>
                          )}
                          {Number(maximum2) > 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {price}RUB
                              <span></span>
                            </div>}
                          {Number(maximum2) <= 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              Распродано!
                              <span></span>
                            </div>}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                          {!cartb && Number(maximum2) > 0 && (<>


                            <div className={style.doublexoom}>
                              <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                            </div>
                            <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                          </>
                          )}
                          {cartb && Number(maximum2) > 0 && (<>
                            <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                            <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                          </>
                          )}
                        </div>
                      </div>
                    )
                  }
                )}

                <br />
              </div>

            </TabPanel>
            <TabPanel>
              <br />
              <div className={style.produtos_container}>
                {!opt.auth && yhodother.map(
                  ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                    let maximum2 = 0
                    for (let i = 0; i < shops2.length; i++) {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                      if (scladqty) {
                        maximum2 = maximum2 + scladqty.scladqty
                        // console.log(shops2[i], scladqty)
                      }
                    }
                    let tt = ''
                    if (photo[0]) {
                      tt = photo[0]
                    }
                    const cart = produtosSelecionados.find((produto) => produto.id == +id);
                    let cartb = false
                    if (cart) {
                      cartb = true
                    }
                    return index <= amount && (
                      <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                        {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                        {/* <img id={id} className={style.img} src={tt} /> */}
                        <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                          <LazyLoadImage
                            id={id}
                            className={style.img}
                            src={tt}
                            alt="Example Image"
                            height="100%"
                            effect="blur"
                          // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                          // width="600px"
                          />
                        </div>
                        {/* </div> */}
                        <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                          </td>
                        }))}
                        <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                          {forwho == 'Для собак' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐶<span></span>
                            </div>
                          )}
                          {forwho == 'Для кошек' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐱<span></span>
                            </div>
                          )}
                          {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              ХИТ
                              <span></span>
                            </div>
                          )}
                          {Number(maximum2) > 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {price}RUB
                              <span></span>
                            </div>}
                          {Number(maximum2) <= 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              Распродано!
                              <span></span>
                            </div>}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                          {!cartb && Number(maximum2) > 0 && (<>


                            <div className={style.doublexoom}>
                              <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                            </div>
                            <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                          </>
                          )}
                          {cartb && Number(maximum2) > 0 && (<>
                            <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                            <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                          </>
                          )}
                        </div>
                      </div>
                    )
                  }
                )}

                <br />
              </div>

            </TabPanel>

          </Tabs >

        </TabPanel>

        <TabPanel>

<Tabs forceRenderTabPanel selectedIndex={selectedIndex4} onSelect={handleSelect4} >
  <TabList style={{
    background: 'none',

    borderRadius: '25px',
    textAlign: 'center',
    display: 'flex',
    position: 'initial',
    margin: '15px',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }}>
    <Tab style={{
      margin: '15px',
      position: 'initial',
      borderBottom: '1px solid #0000',
      fontSize: '14px',

      borderRadius: '25px',
      textAlign: 'center',
      display: 'flex',
      borderRadius: '25px',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}><b>Средства от глистов</b>
    </Tab>
    <Tab style={{
      margin: '15px',
      position: 'initial',
      borderBottom: '1px solid #0000',
      fontSize: '14px',

      borderRadius: '25px',
      textAlign: 'center',
      display: 'flex',
      borderRadius: '25px',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}><b>Средства от блох и клещей</b>
    </Tab>
    <Tab style={{
      margin: '15px',
      position: 'initial',
      borderBottom: '1px solid #0000',
      fontSize: '14px',

      borderRadius: '25px',
      textAlign: 'center',
      display: 'flex',
      borderRadius: '25px',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}><b>Успокоительные средства</b>
    </Tab>
    <Tab style={{
      margin: '15px',
      position: 'initial',
      borderBottom: '1px solid #0000',
      fontSize: '14px',

      borderRadius: '25px',
      textAlign: 'center',
      display: 'flex',
      borderRadius: '25px',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}><b>Уход за глазами</b>
    </Tab>
    <Tab style={{
      margin: '15px',
      position: 'initial',
      borderBottom: '1px solid #0000',
      fontSize: '14px',

      borderRadius: '25px',
      textAlign: 'center',
      display: 'flex',
      borderRadius: '25px',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}><b>Витамины и добавки</b>
    </Tab>
    <Tab style={{
      margin: '15px',
      position: 'initial',
      borderBottom: '1px solid #0000',
      fontSize: '14px',

      borderRadius: '25px',
      textAlign: 'center',
      display: 'flex',
      borderRadius: '25px',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}><b>Капли ушные</b>
    </Tab>
   
  </TabList>
  <TabPanel>
    <br />
    <div className={style.produtos_container}>
      {!opt.auth && aptekadog.filter(pr=>pr.podkategory=='Средства от глистов').map(
        ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

          let maximum2 = 0
          for (let i = 0; i < shops2.length; i++) {
            const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
            if (scladqty) {
              maximum2 = maximum2 + scladqty.scladqty
              // console.log(shops2[i], scladqty)
            }
          }
          let tt = ''
          if (photo[0]) {
            tt = photo[0]
          }
          const cart = produtosSelecionados.find((produto) => produto.id == +id);
          let cartb = false
          if (cart) {
            cartb = true
          }
          return index <= amount && (
            <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
              {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
              {/* <img id={id} className={style.img} src={tt} /> */}
              <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                <LazyLoadImage
                  id={id}
                  className={style.img}
                  src={tt}
                  alt="Example Image"
                  height="100%"
                  effect="blur"
                // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                // width="600px"
                />
              </div>
              {/* </div> */}
              <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
              {shops2.length > 0 && (shops2.map(pr => {
                const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                // console.log(productDots, scladqty)
                return <td className={style.tabletd}>

                  <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                </td>
              }))}
              <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                {forwho == 'Для собак' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐶<span></span>
                  </div>
                )}
                {forwho == 'Для кошек' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐱<span></span>
                  </div>
                )}
                {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ХИТ
                    <span></span>
                  </div>
                )}
                {Number(maximum2) > 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {price}RUB
                    <span></span>
                  </div>}
                {Number(maximum2) <= 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Распродано!
                    <span></span>
                  </div>}


              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                {!cartb && Number(maximum2) > 0 && (<>


                  <div className={style.doublexoom}>
                    <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                  </div>
                  <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                </>
                )}
                {cartb && Number(maximum2) > 0 && (<>
                  <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                  <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                </>
                )}
              </div>
            </div>
          )
        }
      )}

      <br />
    </div>

  </TabPanel>
  <TabPanel>
    <br />
    <div className={style.produtos_container}>
      {!opt.auth && aptekadog.filter(pr=>pr.podkategory=='Средства от блох и клещей').map(
        ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

          let maximum2 = 0
          for (let i = 0; i < shops2.length; i++) {
            const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
            if (scladqty) {
              maximum2 = maximum2 + scladqty.scladqty
              // console.log(shops2[i], scladqty)
            }
          }
          let tt = ''
          if (photo[0]) {
            tt = photo[0]
          }
          const cart = produtosSelecionados.find((produto) => produto.id == +id);
          let cartb = false
          if (cart) {
            cartb = true
          }
          return index <= amount && (
            <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
              {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
              {/* <img id={id} className={style.img} src={tt} /> */}
              <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                <LazyLoadImage
                  id={id}
                  className={style.img}
                  src={tt}
                  alt="Example Image"
                  height="100%"
                  effect="blur"
                // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                // width="600px"
                />
              </div>
              {/* </div> */}
              <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
              {shops2.length > 0 && (shops2.map(pr => {
                const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                // console.log(productDots, scladqty)
                return <td className={style.tabletd}>

                  <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                </td>
              }))}
              <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                {forwho == 'Для собак' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐶<span></span>
                  </div>
                )}
                {forwho == 'Для кошек' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐱<span></span>
                  </div>
                )}
                {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ХИТ
                    <span></span>
                  </div>
                )}
                {Number(maximum2) > 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {price}RUB
                    <span></span>
                  </div>}
                {Number(maximum2) <= 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Распродано!
                    <span></span>
                  </div>}


              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                {!cartb && Number(maximum2) > 0 && (<>


                  <div className={style.doublexoom}>
                    <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                  </div>
                  <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                </>
                )}
                {cartb && Number(maximum2) > 0 && (<>
                  <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                  <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                </>
                )}
              </div>
            </div>
          )
        }
      )}

      <br />
    </div>

  </TabPanel>
  <TabPanel>
    <br />
    <div className={style.produtos_container}>
      {!opt.auth && aptekadog.filter(pr=>pr.podkategory=='Успокоительные средства').map(
        ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

          let maximum2 = 0
          for (let i = 0; i < shops2.length; i++) {
            const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
            if (scladqty) {
              maximum2 = maximum2 + scladqty.scladqty
              // console.log(shops2[i], scladqty)
            }
          }
          let tt = ''
          if (photo[0]) {
            tt = photo[0]
          }
          const cart = produtosSelecionados.find((produto) => produto.id == +id);
          let cartb = false
          if (cart) {
            cartb = true
          }
          return index <= amount && (
            <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
              {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
              {/* <img id={id} className={style.img} src={tt} /> */}
              <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                <LazyLoadImage
                  id={id}
                  className={style.img}
                  src={tt}
                  alt="Example Image"
                  height="100%"
                  effect="blur"
                // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                // width="600px"
                />
              </div>
              {/* </div> */}
              <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
              {shops2.length > 0 && (shops2.map(pr => {
                const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                // console.log(productDots, scladqty)
                return <td className={style.tabletd}>

                  <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                </td>
              }))}
              <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                {forwho == 'Для собак' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐶<span></span>
                  </div>
                )}
                {forwho == 'Для кошек' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐱<span></span>
                  </div>
                )}
                {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ХИТ
                    <span></span>
                  </div>
                )}
                {Number(maximum2) > 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {price}RUB
                    <span></span>
                  </div>}
                {Number(maximum2) <= 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Распродано!
                    <span></span>
                  </div>}


              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                {!cartb && Number(maximum2) > 0 && (<>


                  <div className={style.doublexoom}>
                    <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                  </div>
                  <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                </>
                )}
                {cartb && Number(maximum2) > 0 && (<>
                  <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                  <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                </>
                )}
              </div>
            </div>
          )
        }
      )}

      <br />
    </div>

  </TabPanel>
  <TabPanel>
    <br />
    <div className={style.produtos_container}>
      {!opt.auth && aptekadog.filter(pr=>pr.podkategory=='Уход за глазами').map(
        ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

          let maximum2 = 0
          for (let i = 0; i < shops2.length; i++) {
            const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
            if (scladqty) {
              maximum2 = maximum2 + scladqty.scladqty
              // console.log(shops2[i], scladqty)
            }
          }
          let tt = ''
          if (photo[0]) {
            tt = photo[0]
          }
          const cart = produtosSelecionados.find((produto) => produto.id == +id);
          let cartb = false
          if (cart) {
            cartb = true
          }
          return index <= amount && (
            <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
              {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
              {/* <img id={id} className={style.img} src={tt} /> */}
              <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                <LazyLoadImage
                  id={id}
                  className={style.img}
                  src={tt}
                  alt="Example Image"
                  height="100%"
                  effect="blur"
                // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                // width="600px"
                />
              </div>
              {/* </div> */}
              <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
              {shops2.length > 0 && (shops2.map(pr => {
                const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                // console.log(productDots, scladqty)
                return <td className={style.tabletd}>

                  <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                </td>
              }))}
              <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                {forwho == 'Для собак' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐶<span></span>
                  </div>
                )}
                {forwho == 'Для кошек' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐱<span></span>
                  </div>
                )}
                {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ХИТ
                    <span></span>
                  </div>
                )}
                {Number(maximum2) > 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {price}RUB
                    <span></span>
                  </div>}
                {Number(maximum2) <= 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Распродано!
                    <span></span>
                  </div>}


              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                {!cartb && Number(maximum2) > 0 && (<>


                  <div className={style.doublexoom}>
                    <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                  </div>
                  <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                </>
                )}
                {cartb && Number(maximum2) > 0 && (<>
                  <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                  <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                </>
                )}
              </div>
            </div>
          )
        }
      )}

      <br />
    </div>

  </TabPanel>
  <TabPanel>
    <br />
    <div className={style.produtos_container}>
      {!opt.auth && aptekadog.filter(pr=>pr.podkategory=='Витамины и добавки').map(
        ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

          let maximum2 = 0
          for (let i = 0; i < shops2.length; i++) {
            const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
            if (scladqty) {
              maximum2 = maximum2 + scladqty.scladqty
              // console.log(shops2[i], scladqty)
            }
          }
          let tt = ''
          if (photo[0]) {
            tt = photo[0]
          }
          const cart = produtosSelecionados.find((produto) => produto.id == +id);
          let cartb = false
          if (cart) {
            cartb = true
          }
          return index <= amount && (
            <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
              {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
              {/* <img id={id} className={style.img} src={tt} /> */}
              <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                <LazyLoadImage
                  id={id}
                  className={style.img}
                  src={tt}
                  alt="Example Image"
                  height="100%"
                  effect="blur"
                // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                // width="600px"
                />
              </div>
              {/* </div> */}
              <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
              {shops2.length > 0 && (shops2.map(pr => {
                const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                // console.log(productDots, scladqty)
                return <td className={style.tabletd}>

                  <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                </td>
              }))}
              <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                {forwho == 'Для собак' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐶<span></span>
                  </div>
                )}
                {forwho == 'Для кошек' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐱<span></span>
                  </div>
                )}
                {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ХИТ
                    <span></span>
                  </div>
                )}
                {Number(maximum2) > 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {price}RUB
                    <span></span>
                  </div>}
                {Number(maximum2) <= 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Распродано!
                    <span></span>
                  </div>}


              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                {!cartb && Number(maximum2) > 0 && (<>


                  <div className={style.doublexoom}>
                    <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                  </div>
                  <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                </>
                )}
                {cartb && Number(maximum2) > 0 && (<>
                  <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                  <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                </>
                )}
              </div>
            </div>
          )
        }
      )}

      <br />
    </div>

  </TabPanel>
  <TabPanel>
    <br />
    <div className={style.produtos_container}>
      {!opt.auth && aptekadog.filter(pr=>pr.podkategory=='Капли ушные').map(
        ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

          let maximum2 = 0
          for (let i = 0; i < shops2.length; i++) {
            const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
            if (scladqty) {
              maximum2 = maximum2 + scladqty.scladqty
              // console.log(shops2[i], scladqty)
            }
          }
          let tt = ''
          if (photo[0]) {
            tt = photo[0]
          }
          const cart = produtosSelecionados.find((produto) => produto.id == +id);
          let cartb = false
          if (cart) {
            cartb = true
          }
          return index <= amount && (
            <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
              {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
              {/* <img id={id} className={style.img} src={tt} /> */}
              <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                <LazyLoadImage
                  id={id}
                  className={style.img}
                  src={tt}
                  alt="Example Image"
                  height="100%"
                  effect="blur"
                // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                // width="600px"
                />
              </div>
              {/* </div> */}
              <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
              {shops2.length > 0 && (shops2.map(pr => {
                const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                // console.log(productDots, scladqty)
                return <td className={style.tabletd}>

                  <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                </td>
              }))}
              <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                {forwho == 'Для собак' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐶<span></span>
                  </div>
                )}
                {forwho == 'Для кошек' && (
                  <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    🐱<span></span>
                  </div>
                )}
                {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ХИТ
                    <span></span>
                  </div>
                )}
                {Number(maximum2) > 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {price}RUB
                    <span></span>
                  </div>}
                {Number(maximum2) <= 0 &&
                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Распродано!
                    <span></span>
                  </div>}


              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                {!cartb && Number(maximum2) > 0 && (<>


                  <div className={style.doublexoom}>
                    <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                  </div>
                  <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                </>
                )}
                {cartb && Number(maximum2) > 0 && (<>
                  <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                  <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                </>
                )}
              </div>
            </div>
          )
        }
      )}

      <br />
    </div>

  </TabPanel>


</Tabs >

</TabPanel>
        <TabPanel>

          <Tabs forceRenderTabPanel selectedIndex={selectedIndex2} onSelect={handleSelect2} >
            <TabList style={{
              background: 'none',

              borderRadius: '25px',
              textAlign: 'center',
              display: 'flex',
              position: 'initial',
              margin: '15px',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-evenly'
            }}>
              <Tab style={{
                margin: '15px',
                position: 'initial',
                borderBottom: '1px solid #0000',
                fontSize: '14px',

                borderRadius: '25px',
                textAlign: 'center',
                display: 'flex',
                borderRadius: '25px',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}><b>Игрушки</b>
              </Tab>
              <Tab style={{
                margin: '15px',
                position: 'initial',
                borderBottom: '1px solid #0000',
                fontSize: '14px',

                borderRadius: '25px',
                textAlign: 'center',
                display: 'flex',
                borderRadius: '25px',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}><b>Амуниция</b>
              </Tab>
              <Tab style={{
                margin: '15px',
                position: 'initial',
                borderBottom: '1px solid #0000',
                fontSize: '14px',

                borderRadius: '25px',
                textAlign: 'center',
                display: 'flex',
                borderRadius: '25px',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}><b>Лежанки/Домики</b>
              </Tab>
            </TabList>
            <TabPanel>
              <br />
              <div className={style.produtos_container}>
                {!opt.auth && toys.map(
                  ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                    let maximum2 = 0
                    for (let i = 0; i < shops2.length; i++) {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                      if (scladqty) {
                        maximum2 = maximum2 + scladqty.scladqty
                        // console.log(shops2[i], scladqty)
                      }
                    }
                    let tt = ''
                    if (photo[0]) {
                      tt = photo[0]
                    }
                    const cart = produtosSelecionados.find((produto) => produto.id == +id);
                    let cartb = false
                    if (cart) {
                      cartb = true
                    }
                    return index <= amount && (
                      <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                        {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                        {/* <img id={id} className={style.img} src={tt} /> */}
                        <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                          <LazyLoadImage
                            id={id}
                            className={style.img}
                            src={tt}
                            alt="Example Image"
                            height="100%"
                            effect="blur"
                          // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                          // width="600px"
                          />
                        </div>
                        {/* </div> */}
                        <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                          </td>
                        }))}
                        <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                          {forwho == 'Для собак' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐶<span></span>
                            </div>
                          )}
                          {forwho == 'Для кошек' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐱<span></span>
                            </div>
                          )}
                          {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              ХИТ
                              <span></span>
                            </div>
                          )}
                          {Number(maximum2) > 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {price}RUB
                              <span></span>
                            </div>}
                          {Number(maximum2) <= 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              Распродано!
                              <span></span>
                            </div>}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                          {!cartb && Number(maximum2) > 0 && (<>


                            <div className={style.doublexoom}>
                              <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                            </div>
                            <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                          </>
                          )}
                          {cartb && Number(maximum2) > 0 && (<>
                            <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                            <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                          </>
                          )}
                        </div>
                      </div>
                    )
                  }
                )}

                <br />
              </div>

            </TabPanel>
            <TabPanel>
              <br />
              <div className={style.produtos_container}>
                {!opt.auth && amunation.map(
                  ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                    let maximum2 = 0
                    for (let i = 0; i < shops2.length; i++) {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                      if (scladqty) {
                        maximum2 = maximum2 + scladqty.scladqty
                        // console.log(shops2[i], scladqty)
                      }
                    }
                    let tt = ''
                    if (photo[0]) {
                      tt = photo[0]
                    }
                    const cart = produtosSelecionados.find((produto) => produto.id == +id);
                    let cartb = false
                    if (cart) {
                      cartb = true
                    }
                    return index <= amount && (
                      <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                        {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                        {/* <img id={id} className={style.img} src={tt} /> */}
                        <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                          <LazyLoadImage
                            id={id}
                            className={style.img}
                            src={tt}
                            alt="Example Image"
                            height="100%"
                            effect="blur"
                          // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                          // width="600px"
                          />
                        </div>
                        {/* </div> */}
                        <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                          </td>
                        }))}
                        <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                          {forwho == 'Для собак' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐶<span></span>
                            </div>
                          )}
                          {forwho == 'Для кошек' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐱<span></span>
                            </div>
                          )}
                          {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              ХИТ
                              <span></span>
                            </div>
                          )}
                          {Number(maximum2) > 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {price}RUB
                              <span></span>
                            </div>}
                          {Number(maximum2) <= 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              Распродано!
                              <span></span>
                            </div>}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                          {!cartb && Number(maximum2) > 0 && (<>


                            <div className={style.doublexoom}>
                              <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                            </div>
                            <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                          </>
                          )}
                          {cartb && Number(maximum2) > 0 && (<>
                            <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                            <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                          </>
                          )}
                        </div>
                      </div>
                    )
                  }
                )}

                <br />
              </div>

            </TabPanel>
            <TabPanel>
              <br />
              <div className={style.produtos_container}>
                {!opt.auth && houses.map(
                  ({ mnt, photo, name, price, id, scladqty, forwho, shcode }, index) => {

                    let maximum2 = 0
                    for (let i = 0; i < shops2.length; i++) {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                      if (scladqty) {
                        maximum2 = maximum2 + scladqty.scladqty
                        // console.log(shops2[i], scladqty)
                      }
                    }
                    let tt = ''
                    if (photo[0]) {
                      tt = photo[0]
                    }
                    const cart = produtosSelecionados.find((produto) => produto.id == +id);
                    let cartb = false
                    if (cart) {
                      cartb = true
                    }
                    return index <= amount && (
                      <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                        {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                        {/* <img id={id} className={style.img} src={tt} /> */}
                        <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                          <LazyLoadImage
                            id={id}
                            className={style.img}
                            src={tt}
                            alt="Example Image"
                            height="100%"
                            effect="blur"
                          // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                          // width="600px"
                          />
                        </div>
                        {/* </div> */}
                        <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                          </td>
                        }))}
                        <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                          {forwho == 'Для собак' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐶<span></span>
                            </div>
                          )}
                          {forwho == 'Для кошек' && (
                            <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              🐱<span></span>
                            </div>
                          )}
                          {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              ХИТ
                              <span></span>
                            </div>
                          )}
                          {Number(maximum2) > 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {price}RUB
                              <span></span>
                            </div>}
                          {Number(maximum2) <= 0 &&
                            <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              Распродано!
                              <span></span>
                            </div>}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                          {!cartb && Number(maximum2) > 0 && (<>


                            <div className={style.doublexoom}>
                              <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                            </div>
                            <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                          </>
                          )}
                          {cartb && Number(maximum2) > 0 && (<>
                            <Button text="В Корзину" style={style.vbadge21} onClick={() => setModalCart(true)} />
                            <Button text="Удалить из Корзины " style={style.vbadge21} onClick={() => removeItem(id)} />
                          </>
                          )}
                        </div>
                      </div>
                    )
                  }
                )}

                <br />
              </div>

            </TabPanel>

          </Tabs >

        </TabPanel>

      </Tabs >



    </>
  );
};

export default ForDogsData;
