import React from 'react'
import Button from '../../Button/Button'
import style from './Dashboard.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../context/qtdContext";
import $api from "../../../http/index.ts";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NewProdForm from './Components/NewProdForm/NewProdForm';
import NewOrders from './Components/NewOrders/NewOrders';
import { BACKURL } from '../../../conf.js'
import UsersOrdersStats from './Components/Statistics/Users/UsersOrdersStats';
import { FcAssistant, FcInTransit, FcOk, FcPackage, FcMoneyTransfer } from "react-icons/fc";
import OptUsers from './Components/OptUsers/OptUsers';
import PromoCodesComponent from './Components/PromoCodes/PromoCodes';
import ClicksService from '../../../services/clicks';
import ClicksStats from './Components/Statistics/Clicks/ClicksStats';
import QRComponent from './Components/QR/QR';
import Loading from 'react-fullscreen-loading';

import AllProds from './Components/Factory/Sclad/ScladProd/allprod';
import Cuns from './Components/Factory/Sclad/ScladProd/cuns';
import Lacomstva from './Components/Factory/Sclad/ScladProd/lacomstva';
import OurProds from './Components/Factory/Sclad/ScladProd/racions';
import Farsh from './Components/Factory/Sclad/ScladProd/farsh.js';
import Zamorozka from './Components/Factory/Sclad/ScladProd/zamorozka';
import SuhKorm from './Components/Factory/Sclad/ScladProd/suhkorms.js';
import MoneyStats from './Components/Statistics/Money/MoneyStats';
import AddRess from './Components/Factory/Sclad/AddRes/AddRess';
import Meat from './Components/Factory/Sclad/Sclad/meat';
import Spice from './Components/Factory/Sclad/Sclad/spice';
import Other from './Components/Factory/Sclad/Sclad/other';
import AddSpec from './Components/Factory/Sclad/AddSpec/AddSpec';
import SpecList from './Components/Factory/Sclad/Specs/meat';
import PlanedForm from './Components/Factory/FactoryCircle/PlanedForm';
import FactoryCircle from './Components/Factory/FactoryCircle/FactoryCircle/FactoryCircle';
import NewOptUserForm from './Components/OptUsers/NewOptUserForm/NewOptUserForm';
import Rasshod from './Components/Factory/Sclad/Rasshod/Rasshod';
import Prihod from './Components/Factory/Sclad/Prihod/Prihod';
import ScladHistory from './Components/Factory/Sclad/ScladProd/scladhistory';
import Admins from './Components/Admins/Admins';
import CunsDots from './Components/Factory/Sclad/ScladProdDot/cuns';
import LacomstvaDot from './Components/Factory/Sclad/ScladProdDot/lacomstva';
import RacionsDot from './Components/Factory/Sclad/ScladProdDot/racions';
import ZamorozkaDot from './Components/Factory/Sclad/ScladProdDot/zamorozka';
import Users from './Components/Users/Users.js';
import Catalog from './Components/Factory/Sclad/ScladProd/catalog.js';
import ModalEditProduct from './Components/Modal/ModalEditProduct.js';
import Shops from './Components/Shops/Shops.js';
import SuskaGroups from './Components/Factory/Sclad/ScladProd/suskaGroups.js';

const Dashboard = () => {
  const { editprod_id, editprod_suh, modalEditProduct, productGroups, setproductGroups, setScladDot, setAdmins, setUsers, setScladHistory, factory, setfactory, spec, setSpec, clicks, setClicks, setQR, PromoCodes, setPromoCodes, optUsers, setOptUsers, setStat, servOrders, setServOrders, setSclad, setServerProd, setAdmin, admin } = useContext(QtdContext);
  // setSclad(prelist)
  // setServerProd(prelist);
  const [loading, setLoading] = useState(false);

  const [badgeV, setbadgeV] = useState({
    wp: 0,
    p: 0,
    oz: 0,
    od: 0,
    ok: 0
  })
  useEffect(() => {

    setLoading(true)
    const getdata = async () => {
      // console.log(admin)
      const clicks = async () => {
        const data = await $api.get(`${BACKURL}/api/clicks/getall`)
        setClicks(data.data)

      }
      // const ClickData = await ClicksService.noaddone()
      const AllOrders = async () => {
        const data = await $api.get(`${BACKURL}/api/utbteam/allorders`)
        setServOrders(data.data)
        setbadgeV({
          wp: data.data.filter((ao) => ao.status == 'Ожидает оплаты!').length,
          p: data.data.filter((ao) => ao.status == 'Оплачен!').length,
          oz: data.data.filter((ao) => ao.status == 'Ожидает подтверждения!').length,
          od: data.data.filter((ao) => ao.status == 'Подтвержден!').length,
          ok: data.data.filter((ao) => ao.status == 'Ожидает доставки!').length,
        })
      }
      const Stat = async () => {
        const startDate = ('2024-04-01');
        const endDate = ('2024-04-30');
        const data = await $api.post(`${BACKURL}/api/utbteam/getstat`, { startDate: startDate, endDate: endDate })
        setStat(data.data)

      }

      const OPTU = async () => {
        const data = await $api.get(`${BACKURL}/api/utbteam/getoptusers`)
        setOptUsers(data.data)

      }
      const PromoCodes = async () => {
        const data = await $api.get(`${BACKURL}/api/utbteam/promocodes/getall`)
        setPromoCodes(data.data)

      }
      const QRCodes = async () => {
        const data = await $api.get(`${BACKURL}/api/utbteam/qr/getall`)
        setQR(data.data)

      }
      const Specs = async () => {
        const data = await $api.get(`${BACKURL}/api/spec/getall`)
        setSpec(data.data)

      }
      const factoryData = async () => {
        const data = await $api.get(`${BACKURL}/api/factory/getall`)
        setfactory(data.data)

      }
      const historyData = async () => {
        const data = await $api.get(`${BACKURL}/api/utbteam/history/getall`)
        // console.log(data.data)
        setScladHistory(data.data)

      }
      const setAdmins2 = async () => {
        const data = await $api.get(`${BACKURL}/api/utbteam/admins/getall`)
        // console.log(data.data)
        setAdmins(data.data)

      }
      const setusers = async () => {
        const data = await $api.get(`${BACKURL}/api/utbteam/users/getall`)
        // console.log(data.data)
        setUsers(data.data)

      }
      const setDotsSclad = async () => {
        const data = await $api.get(`${BACKURL}/dots/utbteam/dots/getall`)
        // console.log(data.data)
        setScladDot(data.data)

      }


      const promisall = await Promise.all([
        AllOrders(), clicks(), Stat(), OPTU(), PromoCodes(), QRCodes(), setAdmins2(), factoryData(), historyData()
      ]

      )
      const promiseall2 = await Promise.all([
        setDotsSclad(), Specs(), setusers()
      ]

      )
      setLoading(false)

      // console.log('Stat', clicks.data)
      // console.log('dwdwe', AllOrders.data)
      // console.log('Stat', Stat.data)
      // console.log('Stat', Stat.data)

      // console.log('Stat', Stat.data)

      // console.log('Stat', Stat.data)
      // console.log('Stat', Stat.data)

      // const culc = (status) =>{
      // const t = AllOrders.data.filter((ao)=>ao.status==status).length
      // return t
      // }

      // console.log(admin.role)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      // localStorage.setItem("servOrders", JSON.stringify(servOrders));
    }
    getdata()
  }, [])

  const LogOut = async () => {
    await $api.post(`${BACKURL}/api/utbteam/logout`)
    setAdmin({ auth: false, user: '' });

    return
  }
  return (
    <>
      <div className={style.container}>
        <Button onClick={LogOut} text='Выход'></Button>
        <div style={{ 'width': '100%' }}>
          <Tabs >
            <TabList>
              {(admin.role == 'GOD' || admin.role == 'Админ' || admin.role == 'Кладовщик' || admin.role == 'Модератор' || admin.role == 'Оператор Заказов') && (
                <Tab>Заказы</Tab>

              )}
              {(admin.role == 'GOD' || admin.role == 'Админ' || admin.role == 'Кладовщик' || admin.role == 'Модератор') && (
                <Tab>Склады</Tab>

              )}
              {/* {(admin.role == 'GOD' || admin.role == 'Админ' || admin.role == 'Кладовщик') && (
                <Tab>Производство</Tab>

              )} */}
              {(admin.role == 'GOD' || admin.role == 'Админ' || admin.role == 'Кладовщик') && (
                <Tab>Точки</Tab>

              )}
              {/* {(admin.role == 'GOD' || admin.role == 'Админ') && (
                <Tab>Оптовые Клиенты</Tab>

              )} */}
              {(admin.role == 'GOD' || admin.role == 'Админ') && (
                <Tab>Реклама</Tab>

              )}
              {(admin.role == 'GOD' || admin.role == 'Админ') && (
                <Tab>Статистика</Tab>

              )}
              {(admin.role == 'GOD' || admin.role == 'Админ') && (
                <Tab>Пользователи</Tab>

              )}
              {(admin.role == 'GOD') && (
                <Tab>Админы</Tab>

              )}

            </TabList>

            {(admin.role == 'GOD' || admin.role == 'Админ' || admin.role == 'Кладовщик' || admin.role == 'Модератор' || admin.role == 'Оператор Заказов') && (
              <TabPanel>
                <Tabs forceRenderTabPanel>
                  <TabList style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexWrap: 'wrap' }}>
                    <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Ожидают оплаты ({badgeV.wp})<FcMoneyTransfer className={style.icon} /></Tab>
                    <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Оплачен ({badgeV.p})<FcAssistant className={style.icon} /></Tab>
                    <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Ожидают подтверждения ({badgeV.oz})<FcAssistant className={style.icon} /></Tab>
                    <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Подтвержденные ({badgeV.od})<FcPackage className={style.icon} /></Tab>
                    <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Собранные ({badgeV.ok})<FcInTransit className={style.icon} /></Tab>
                    <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Полученные<FcOk className={style.icon} /></Tab>

                  </TabList>
                  <TabPanel>
                    <p>Ожидаем онлайн оплату!</p>
                    <NewOrders status={'Ожидает оплаты!'} />
                  </TabPanel>
                  <TabPanel>
                    <p>Оплачен! Необходимо позвонить и уточнить адрес и время доставки!</p>
                    <NewOrders status={'Оплачен!'} />
                  </TabPanel>
                  <TabPanel>
                    <p>Необходимо позвонить и проговорить заказ, уточнить адрес и время доставки, способ оплаты!</p>
                    <NewOrders status={'Ожидает подтверждения!'} />
                  </TabPanel>
                  <TabPanel>
                    <p>Заказ необходимо собрать на складе к нужному сроку доставки и сменить статус заказа! Для контроля - можно не показывать точный адрес доставки до нажатия кнопки</p>
                    <NewOrders status={'Подтвержден!'} />
                  </TabPanel>
                  <TabPanel>
                    <p>Заказ необходимо доставить!</p>
                    <NewOrders status={'Ожидает доставки!'} />
                  </TabPanel>
                  <TabPanel>
                    <NewOrders status={'Получен!'} />
                  </TabPanel>

                </Tabs>
              </TabPanel>

            )}
            {(admin.role == 'GOD' || admin.role == 'Админ' || admin.role == 'Кладовщик' || admin.role == 'Модератор') && (
              <TabPanel>
                <Tabs forceRenderTabPanel>
                  <TabList>
                    {admin.role == 'GOD' && (

                      <Tab>Ресурсы </Tab>
                    )}
                    <Tab>Продукция </Tab>

                  </TabList>
                  {admin.role == 'GOD' && (
                    <TabPanel>
                      <Tabs forceRenderTabPanel>
                        <TabList>
                          <Tab>Остаток </Tab>
                          <Tab>Создать новый товар </Tab>
                          <Tab>Оприходывать</Tab>
                          <Tab>Списать </Tab>
                          <Tab>История </Tab>

                        </TabList>
                        <TabPanel>
                          <Tabs forceRenderTabPanel>
                            <TabList>
                              <Tab>Мясо </Tab>
                              <Tab>Специи </Tab>
                              <Tab>Прочее</Tab>

                            </TabList>
                            <TabPanel>
                              <p>Мясо</p>
                              <Meat></Meat>
                            </TabPanel>
                            <TabPanel>
                              <p>Специи</p>
                              <Spice></Spice>
                            </TabPanel>
                            <TabPanel>
                              <p>Прочее</p>
                              <Other></Other>
                            </TabPanel>

                          </Tabs>
                        </TabPanel>
                        <TabPanel>
                          <p>Создать новый товар</p>
                          <AddRess></AddRess>
                        </TabPanel>
                        <TabPanel>
                          <p>Оприходывать по накладной</p>
                        </TabPanel>
                        <TabPanel>
                          <p>Списать</p>
                        </TabPanel>
                        <TabPanel>
                          <p>История</p>
                        </TabPanel>
                      </Tabs>
                    </TabPanel>
                  )}


                  <TabPanel>
                    <Tabs forceRenderTabPanel>
                      <TabList>

                        <Tab>Все товары</Tab>

                        {/* <Tab>Заморозка</Tab> */}
                        <Tab>Наша продукция</Tab>
                        <Tab>Каталог</Tab>
                        {(admin.role == 'GOD' || admin.role == 'Модератор') && (<>
                          <Tab>Новый Товар</Tab>
                        </>)}
                        {(admin.role == 'GOD') && (<>
                          <Tab>Оприходывать</Tab>
                          <Tab>Списать</Tab>

                          <Tab>История</Tab>
                        </>)}
                      </TabList>


                      <TabPanel>
                        <AllProds />
                        {/* </TabPanel>
                   
                               <TabPanel>
                            <Zamorozka /> */}
                      </TabPanel>
                      <TabPanel>
                        <Tabs forceRenderTabPanel>
                          <TabList>
                            <Tab>Консервы</Tab>
                            <Tab>Лакомства</Tab>
                            <Tab>Рационы</Tab>
                            <Tab>Фарши</Tab>
                            <Tab>Заморозка</Tab>

                          </TabList>
                          <TabPanel>
                            <OurProds kategory={'мясные консервы для'} />
                          </TabPanel>
                          <TabPanel>
                            {/* <OurProds kategory={'сушенная продукция для собак'} /> */}
                            <SuskaGroups></SuskaGroups>
                          </TabPanel>
                          <TabPanel>
                            <OurProds kategory={'рацион'} />
                          </TabPanel>
                          <TabPanel>
                            <OurProds kategory={'фарш'} />
                          </TabPanel>
                          <TabPanel>
                            {/* <SuskaGroups></SuskaGroups> */}
                            <OurProds kategory={'отдельная мороженная продукция'} />
                          </TabPanel>
                        </Tabs>
                      </TabPanel>
                      <TabPanel>
                        <Catalog />
                      </TabPanel>
                      {(admin.role == 'GOD' || admin.role == 'Модератор') && (<>
                        <TabPanel>
                          <NewProdForm />
                        </TabPanel>

                        {(admin.role == 'GOD') && (<>

                          <TabPanel>
                            <p>Оприходывать</p>
                            <Prihod></Prihod>
                          </TabPanel>
                          <TabPanel>
                            <p>Списать</p>
                            <Rasshod></Rasshod>
                          </TabPanel>
                          <TabPanel>
                            <p>История</p>
                            <ScladHistory></ScladHistory>
                          </TabPanel>
                        </>)}
                      </>)}
                    </Tabs>
                  </TabPanel>
                </Tabs>
              </TabPanel>
            )}
            {(admin.role == 'GOD' || admin.role == 'Админ' || admin.role == 'Кладовщик') && (
              <TabPanel>
                <Shops />
              </TabPanel>
            )}


            {(admin.role == 'GOD' || admin.role == 'Админ') && (
              <TabPanel>

                <Tabs forceRenderTabPanel>
                  <TabList>
                    <Tab>Промокоды</Tab>
                    <Tab>QR</Tab>

                  </TabList>
                  <TabPanel>
                    <PromoCodesComponent />
                  </TabPanel>
                  <TabPanel>
                    <QRComponent />
                  </TabPanel>


                </Tabs>
              </TabPanel>
            )}
            {(admin.role == 'GOD' || admin.role == 'Админ') && (
              <TabPanel>
                <Tabs forceRenderTabPanel>
                  <TabList>
                    <Tab>Общая Статистика</Tab>
                    {/* <Tab>Деньги</Tab> */}
                    <Tab>Клики</Tab>

                  </TabList>
                  <TabPanel>
                    <UsersOrdersStats />
                  </TabPanel>
                  {/* <TabPanel>
                    <MoneyStats />
                  </TabPanel> */}
                  <TabPanel>
                    <p>Клики</p>
                    <ClicksStats />
                  </TabPanel>


                </Tabs>
              </TabPanel>
            )}
            {(admin.role == 'GOD' || admin.role == 'Админ') && (
              <TabPanel>
                <Users></Users>

              </TabPanel>
            )}
            {(admin.role == 'GOD') && (
              <TabPanel>
                <Admins></Admins>

              </TabPanel>
            )}

          </Tabs>
        </div>
      </div>
      {modalEditProduct && (
        <ModalEditProduct
          id={editprod_id}
          suh={editprod_suh}
        />

      )}
       {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  )
}

export default Dashboard