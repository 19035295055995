import React, { useContext, useEffect, useState } from "react";
import { images } from "./JsonImages";
import style from "./Cards.module.css";
import img5 from '../Assets/CardsImages/5.png';
import img6 from '../Assets/CardsImages/6.png';
import img1 from '../Assets/CardsImages/1.png';
import img12 from '../Assets/CardsImages/12.png';
import img2 from '../Assets/CardsImages/suh.png';
import dogapteka from '../Assets/CardsImages/dogapteka.png';
import yhod from '../Assets/CardsImages/3634685.png';
import shampo from '../Assets/CardsImages/shampo.png';
import toys from '../Assets/CardsImages/4650001617224.png';

import { useNavigate, useLocation } from "react-router-dom";
import { QtdContext } from "../../context/qtdContext";


const CardsForDog = () => {
  const { serverProd, produtosSelecionados, menu, setMenu,tabs, setTabs  } = useContext(QtdContext);

  // const drydey = serverProd.filter((prod) => prod.kategory.includes("сухой корм"))

  const GoInfo = (imageId, name) => {
    setTabs(imageId)
    navigate(`/fordogs/`);
  };
  const navigate = useNavigate();

  return (
    <div className={style.cards}>


      <div key={'Сухой Корм'} className={style.card} onClick={() => {
        GoInfo('сухой корм')
        setMenu(false)
      }}>
        <img className={style.imgcard} src={img2} />
        <p className={style.text_card}>{'Сухой Корм'}</p>
      </div>

      <div key={'Уход'} className={style.card} onClick={() => {
        GoInfo('Уход')
        setMenu(false)
      }
      }>
        <img className={style.imgcard} src={shampo} />
        <p className={style.text_card}>{'Уход'}</p>
      </div>
      <div key={'ВетАптека'} className={style.card} onClick={() => {
        GoInfo('ВетАптека')
        setMenu(false)
      }}>
        <img className={style.imgcard} src={dogapteka} />
        <p className={style.text_card}>{'ВетАптека'}</p>
      </div>
      <div key={'Аксессуары'} className={style.card} onClick={() => {
        GoInfo('Аксессуары')
        setMenu(false)
      }}>
        <img className={style.imgcard} src={toys} />
        <p className={style.text_card}>{'Аксессуары'}</p>
      </div>

    </div>
  );
};

export default CardsForDog;
