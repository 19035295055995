
import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Modal.module.css'
// import Title from "../../Title";
import Button from '../../../../Button/Button'
import $api from "../../../../../http/index.ts";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AiFillDelete } from "react-icons/ai";

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
import axios from "axios";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { FileUploader } from "react-drag-drop-files";
const ModalEditProductGroups = ({ id, suh }) => {

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const {servOrders, sclad, setproductGroups, productGroups, setServerProd, serverProd, modalEditProductGroups, setModalEditProductGroups } = useContext(QtdContext);
  const [newValue, setNeValue] = useState('')
  const [files, setFiles] = useState([]);
  const [fileURLs, setFileURLs] = useState([]);
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container124" && setModalEditProductGroups(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  // const { modal, setModal } = useState(false);
  const [loading, setLoading] = useState(false)
  const [imageFile, setImageFile] = useState(null);
  // useEffect(() => {
  //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  // }, [])
  console.log(id)
  // const [file, setFile] = useState(null);
  // const handleChange = (file) => {
  //   if (file.type === 'image/heic' || file.type === 'image/heif') {
  //     alert('HEIC/HEIF images are not supported. Please convert your image to JPEG or PNG.');
  //   }
  //   setFile(file);
  //   setFileURL(URL.createObjectURL(file))
  //   // console.log(file)
  // };

  // setFileURL(product.photo[0])
  // const [fileURL, setFileURL] = useState(product.photo[0]);

  const [newProd, setNewProd] = useState(
    {
      name: "",
      kategory: '',
      podkategory: '',
      sh50: '',
      sh100: '',
      sh1000: '',
      mnt50: '',
      mnt100: '',
      mnt1000: '',

      brand: '',

    }
  )
  useEffect(() => {


    const product = productGroups.find(pr => pr.id == id)
    console.log(product)
    setFileList(product.photo.map(url => ({ file: null, url })));
    setNewProd({ ...product, photo: [product.photo[0]], about: product.text })

  }, [])

  // console.log(product)
  const [err, setErr] = useState({})
  const create = async (e) => {
    try {
      e.preventDefault();

      let t = {
        name: false,
        sh50: false,
        sh100: false,
        sh1000: false,
      };

      if (!newProd.name) {
        t.name = 'Наименование обязательно к заполнению!';
      }
 

      console.log(t);
      setErr(t);

      if (
        newProd.name &&
       
        !t.name 
     
      ) {
        const formData = new FormData();

        // ✅ Используем fileList вместо files
        fileList.forEach((item, index) => {
          formData.append(`images`, item.file); // `images` - массив на сервере
        });

        formData.append('name', newProd.name);
        formData.append('podkategory', newProd.podkategory);
        formData.append('sh50', newProd.sh50);
        formData.append('photo', JSON.stringify(fileList.map(item => item.url))); // ✅ Теперь берем URL из fileList
        formData.append('sh100', newProd.sh100);
        formData.append('sh1000', newProd.sh1000);
       

        setLoading(true);

        const response = await $api.post(`${BACKURL}/updateprodgroup/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response) {
          console.log(response.data);
          setproductGroups(productGroups.map(pr => (pr.id === response.data.id ? response.data : pr)));
          setModalEditProductGroups(false);
        } else {
          console.error('Ошибка при загрузке изображения');
        }

        setLoading(false);
        alert('Готово!');
      }
    } catch (e) {
      setLoading(false);
      alert('Ошибка!');
    }
  };


  const [fileList, setFileList] = useState([]); // [{ file: File, url: "blob:https://..." }]

  const handleChange = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles).map(file => ({
      file,
      url: URL.createObjectURL(file)
    }));

    setFileList(prevList => [...prevList, ...newFiles]);
  };

  const removeImage = (index) => {
    setFileList(prevList => prevList.filter((_, i) => i !== index));
  };
  return (
    <>
      {modalEditProductGroups && (
        <div onClick={closeModal} id="container124" className={style.modal_container} >

          <div className={style.modal} style={{ background: 'antiquewhite', height: '70%', padding: '20px' }}>

            <div className={style.uploaddiv}>

              <div className={style.imgcont} style={{ display: "flex", flexWrap: "wrap" }}>

                {fileList.map((item, index) => (
                  <div key={index} style={{ display: "inline-block", position: "relative" }}>
                    <LazyLoadImage
                      className={style.img}
                      src={item.url}
                      alt={`Preview ${index + 1}`}
                      height="100%"
                      effect="blur"
                      style={{ margin: "5px", width: "100px", height: "100px" }}
                    />
                    <AiFillDelete
                      onClick={() => removeImage(index)}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "red",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  </div>
                ))}

              </div>

              <FileUploader handleChange={handleChange} name="files" multiple />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around',width:'100%', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.name ? 'red' : 'black' }} for="name">Введите Наименование:</label>

                <input value={newProd.name} type="text" id="name" name="name" onChange={(event) => {
                  setNewProd({ ...newProd, name: event.target.value });
                }} />
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around',width:'100%', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.podkategory ? 'red' : 'black' }} for="podkategory">Введите Подкатегорию:</label>
                <select style={{ height: "50px" }} value={newProd.podkategory} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, podkategory: event.target.value });
                }}>
                  <option value="">--Подкатегория--</option>
                  <option value={'Говядина'}>Говядина</option>
                  <option value={'Свинина'}>Свинина</option>
                  <option value={'Курица'}>Курица</option>
                  <option value={'Индейка'}>Индейка</option>
                  <option value={'Утка'}>Утка</option>
                  <option value={'Кролик'}>Кролик</option>
                  <option value={'Рыба'}>Рыба</option>

                </select> 
              </div>

             <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around',width:'100%', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.sh50 ? 'red' : 'black' }} for="pet-select">ШК 50гр:</label>

                <select style={{ height: "50px" }} value={newProd.sh50} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, sh50: event.target.value });
                }}>
                  <option value="">--ШК 50гр--</option>
                  {sclad.filter(pr => pr.kategory == 'сушенная продукция для собак').map(pr => {
                    return (
                      <option value={pr.shcode}>({pr.shcode}){pr.name}</option>

                    )
                  })}

                </select> 
                

              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around',width:'100%', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.sh100 ? 'red' : 'black' }} for="pet-select">ШК 100гр:</label>

                <select style={{ height: "50px" }} value={newProd.sh100} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, sh100: event.target.value });
                }}>
                  <option value="">--ШК 100гр--</option>
                  {sclad.filter(pr => pr.kategory == 'сушенная продукция для собак').map(pr => {
                    return (
                      <option value={pr.shcode}>({pr.shcode}){pr.name}</option>

                    )
                  })}

                </select> 

              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around',width:'100%', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.sh1000 ? 'red' : 'black' }} for="pet-select">ШК 1кг:</label>

                <select style={{ height: "50px" }} value={newProd.sh1000} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, sh1000: event.target.value });
                }}>
                  <option value="">--ШК 1кг--</option>
                  {sclad.filter(pr => pr.kategory == 'сушенная продукция для собак').map(pr => {
                    return (
                      <option value={pr.shcode}>({pr.shcode}){pr.name}</option>

                    )
                  })}

                </select> 

              </div>
         
         
          

 

            </div>



            {!loading && (
              <Button
                text="Изменить товар"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={create}
              />
            )}
            {loading && (
              <Button
                text="Изменяем..."

                background="var(--primary-color)"
                color="white"
                width="100%"
                disabled={true} />
            )}

          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default ModalEditProductGroups;
