import React from 'react'
import Button from '../../Button/Button'
import style from './Contato.module.css'
import Title from "../../Title";
import { useEffect, useState, useContext } from "react";

const PersonalData = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])

  return (
    <>
      <Title text="Политика обработки персональных данных" />
      <div className={style.border} style={{ textAlign: 'start' }}>
        {/* <h2 style={{ textAlign: 'start', marginTop:'20px' }}>Политика обработки персональных данных</h2> */}

        <h3 style={{ marginTop: '30px', marginBottom: '15px' }}>1. Общие положения</h3>
        <p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП Бессонов Роман Анатольевич (далее – Оператор).</p>
        <p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
        <p>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://kotopes39.ru.</p>

        <h3 style={{ marginTop: '30px', marginBottom: '15px' }}>2. Основные понятия, используемые в Политике</h3>
        <p>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.</p>
        <p>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
        <p>2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://kotopes39.ru.</p>
        <p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.</p>
        <p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
        <p>2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
        <p>2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
        <p>2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://kotopes39.ru.</p>
        <p>2.9. Пользователь – любой посетитель веб-сайта https://kotopes39.ru.</p>
        <p>2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
        <p>2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</p>
        <p>2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.</p>
        <p>2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</p>


        <h3 style={{ marginTop: '30px', marginBottom: '15px' }}>3. Оператор может обрабатывать следующие персональные данные Пользователя</h3>
        <p>3.1. <span style={{ background: 'yellow' }}>Фамилия, имя, отчество</span></p>
        <p>3.2. <span>Электронный адрес</span></p>
        <p>3.3. <span>Номера телефонов</span></p>
        <p>3.4. <span>Год, месяц, дата и место рождения</span></p>
        <p>3.5. <span>Адрес фактического места проживания и регистрации по месту жительства и (или) по месту пребывания</span></p>
        <p>3.6. <span>Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</span></p>
        <p>3.7. <span>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</span></p>

      </div>
      {/* <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Написать нам прямо тут!</h1>
      <div className={style.container_form}>
        <form action='post' className={style.form}>
          <label for="name">Ваше Имя:</label>
          <input type="text" id="name" name="name" />
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" />
          <p>Введите ваше сообщение</p>
          <textarea
            rows="8"
            id="experiencia"
            name="experiencia"
          ></textarea>
          <Button text='Отправить' />
        </form>
      </div> */}
    </>
  )
}

export default PersonalData