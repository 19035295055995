
import React, { useEffect, useContext, useState } from "react";
import { produtos } from "./JsonProdutosDry";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
import ButtonsModalVes from "../Modal/ButtonsModal/ButtonsModalVes";
import ModalDonatePriuts from "../Pages/DashBoard/Components/Modal/ModalDonatePriuts";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ProdutosDry = ({ amount }) => {
  // const “ = JSON.parse(localStorage.getItem("products"));
  const { shops,  tabs2, setTabs2,donatePriutModal, setDonatePriutModal, productDots, productGroups, setproductGroups, serverProd, produtosSelecionados, opt, tabs, setTabs, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
  const navigate = useNavigate()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [selectedIndex2, setSelectedIndex2] = useState(0)
  const drydey = serverProd.filter((prod) => prod.kategory.includes("сушенная продукция"))
  // const drydey = nosorted.sort((a, b) => a.name.localeCompare(b.name));
  const shops2 = shops.filter(pr => pr.showed)
  // console.log(drydey)
    const [donInfo, setDonInfo] = useState({})
  
  const Donate = (id, maxumum) => {
    setDonInfo({ id, maxumum })
    setDonatePriutModal(true)
  };
  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const changeItensCart = (id) => {
    const produtos = serverProd

    const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
    const produtoDados = produtos.find((produto) => produto.id == +id);



    const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  const GoInfo = (imageId, name) => {
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd: serverProd,

        produtosSelecionados: produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/lacomstva"

      }
    });
  };
  const handleSelect = (index) => {
    // console.log(index)
    if (index == 1) {
      setTabs('Свинина')
    }
    if (index == 2) {
      setTabs('Курица')
    }
    if (index == 3) {
      setTabs('Индейка')
    }

    if (index == 4) {
      setTabs('Утка')
    }
    if (index == 5) {
      setTabs('Кролик')
    }
    if (index == 6) {
      setTabs('Рыба')
    }
    if (index == 0) {
      setTabs('Говядина')
    }


    setSelectedIndex(index);
  };
  const availebleGroups = productGroups.sort((a, b) => {
//     const aprod50 = serverProd.find((produto) => produto.shcode == a.sh50)
//     const aprod100 = serverProd.find((produto) => produto.shcode == a.sh100)
//     const aprod1000 = serverProd.find((produto) => produto.shcode == a.sh1000)
// // console.log()
//     const bprod50 = serverProd.find((produto) => produto.shcode == b.sh50)
//     const bprod100 = serverProd.find((produto) => produto.shcode == b.sh100)
//     const bprod1000 = serverProd.find((produto) => produto.shcode == b.sh1000)

    // let maximumA50 = 0
    // let maximumA100 = 0
    // let maximumA1000 = 0
    // let maximumB50 = 0
    // let maximumB100 = 0
    // let maximumB1000 = 0
    // for (let i = 0; i < shops2.length; i++) {
    //   const scladqtyA50 = aprod50?productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh50 && pd.prod_id == aprod50.id && shops2[i].id == pd.shop_id):false
    //   const scladqtyA100 =aprod100? productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh100 && pd.prod_id == aprod100.id && shops2[i].id == pd.shop_id):false
    //   const scladqtyA1000 = aprod1000?productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh1000 && pd.prod_id == aprod1000.id && shops2[i].id == pd.shop_id):false
    //   if (scladqtyA50) {
    //     maximumA50 = maximumA50 + scladqtyA50.scladqty
    //     // console.log(shops2[i], scladqtyA50)
    //   }
    //   if (scladqtyA100) {
    //     maximumA100 = maximumA100 + scladqtyA100.scladqty
    //     // console.log(shops2[i], scladqtyA100)
    //   }
    //   if (scladqtyA1000) {
    //     maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
    //     // console.log(shops2[i], scladqtyA1000)
    //   }

    //   const scladqtyB50 = bprod50?productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh50 && pd.prod_id == bprod50 ? bprod50.id : false && shops2[i].id == pd.shop_id):false
    //   const scladqtyB100 = bprod100?productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh100 && pd.prod_id == bprod100 ? bprod100.id : false && shops2[i].id == pd.shop_id):false
    //   const scladqtyB1000 = bprod1000?productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh1000 && pd.prod_id == bprod1000 ? bprod1000.id : false && shops2[i].id == pd.shop_id):false


    //   if (scladqtyB50) {
    //     maximumB50 = maximumB50 + scladqtyB50.scladqty
    //     // console.log(shops2[i], scladqtyB50)
    //   }
    //   if (scladqtyB100) {
    //     maximumB100 = maximumB100 + scladqtyB100.scladqty
    //     // console.log(shops2[i], scladqtyB100)
    //   }
    //   if (scladqtyB1000) {
    //     maximumB1000 = maximumB1000 + scladqtyB1000.scladqty
    //     // console.log(shops2[i], scladqtyB1000)
    //   }
    // }

    // if (( maximumA50 > 0  ||  maximumA100 > 0 ||  maximumA1000 > 0) && ( maximumB50 === 0 ||  maximumB100 === 0 ||  maximumB1000 === 0)) return -1;
    // if (( maximumB50 === 0 &&  maximumB100 === 0 &&  maximumB1000 === 0)) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });

  return (
    <>
     
      <br />
     <Tabs selectedIndex={selectedIndex} onSelect={handleSelect} >
        <TabList style={{
          background: 'none',

          borderRadius: '25px',
          textAlign: 'center',
          display: 'flex',
          position: 'initial',
          margin: '15px',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-evenly'
        }}>

          <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Говядина</b></Tab>
          <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Свинина</b></Tab>
           <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Курица</b></Tab>
           <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Индейка</b></Tab>
           <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Утка</b></Tab>
           <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Кролик</b></Tab>
            <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Рыба</b></Tab>
        
        </TabList>
         <TabPanel>
                <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>

        {!opt.auth && availebleGroups.filter(pr=>pr.podkategory=='Говядина').map(
          ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
            const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
            const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
            const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
            const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
            const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

            let maximumA50 = 0
            let maximumA100 = 0
            let maximumA1000 = 0
                console.log('prod50',prod50)

            for (let i = 0; i < shops2.length; i++) {
              const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
              const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
              const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
              if (scladqtyA50) {
                maximumA50 = maximumA50 + scladqtyA50.scladqty
                // console.log(shops2[i], scladqtyA50)
              }
              if (scladqtyA100) {
                maximumA100 = maximumA100 + scladqtyA100.scladqty
                // console.log(shops2[i], scladqtyA100)
              }
              if (scladqtyA1000) {
                maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
                // console.log(shops2[i], scladqtyA1000)
              }


            }



            if (prod50 || prod100 || prod1000) {
              return index <= amount && (
                <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
                  {/* <img id={id} className={style.img} src={tt} /> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.imgcont} style={{ height: '350px' }}>
                    <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt={name}
                      height="100%"
                      effect="blur"
                    // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                    // width="600px"
                    />
                  </div>
                  <p
                    // onClick={() => GoInfo(id, name)}
                    id={id}>{name}</p>

                  {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {/* {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>} */}

                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>

                  <div style={{
                    //  margin: '5%',
                    flexWrap: 'wrap', display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'
                  }} className={style.container_price}>

                    {prod50 && Number(maximumA50) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>50гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod50.price}РУБ/{prod50.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></p>



                          </td>
                        }))}

                        {cart50 && (<><h6>Кол-во:</h6><h5> {prod50.quantidade}{prod50.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart50 && Number(maximumA50) > 0 && (<>

                            <div  className={style.doublexoom}>
 <ButtonsModal id={prod50.id} max={maximumA50} />
                        </div>
                           
                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6>

                          {cart50 && Number(maximumA50) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />

                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod50.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA50) > 0 && prod50.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod100 && Number(maximumA100) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>100гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



                          </td>
                        }))}

                        {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
                          {!cart100 && Number(maximumA100) > 0 && (<>


                            <div  className={style.doublexoom}>
                            <ButtonsModal id={prod100.id} max={maximumA100} />

                        </div>
                            {/* {' '}  <h4> {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

                          {cart100 && Number(maximumA100) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod100.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA100) > 0 && prod100.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod1000 && Number(maximumA1000) > 0.3 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>Развес</h2>
                        <h6>Цена:</h6>
                        <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



                          </td>
                        }))}
                        {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart1000 && Number(maximumA1000) > 0 && (<>

                            <div  className={style.doublexoom}>
                            <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

                        </div>
                            {/* {' '} <h4> {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

                          {cart1000 && Number(maximumA1000) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(cart1000.quantidade) * Number(cart1000.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(cart1000.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA1000) > 0 && prod1000.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
                          </div>
                        )}
                      </div>
                    )}
                    {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Распродано!
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              )
            } else return null

          }
        )}
  </div>
  </TabPanel>
<TabPanel>
        <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>

        {!opt.auth && availebleGroups.filter(pr=>pr.podkategory=='Свинина').map(
          ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
            const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
            const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
            const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
            const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
            const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

            let maximumA50 = 0
            let maximumA100 = 0
            let maximumA1000 = 0
                console.log('prod50',prod50)

            for (let i = 0; i < shops2.length; i++) {
              const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
              const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
              const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
              if (scladqtyA50) {
                maximumA50 = maximumA50 + scladqtyA50.scladqty
                // console.log(shops2[i], scladqtyA50)
              }
              if (scladqtyA100) {
                maximumA100 = maximumA100 + scladqtyA100.scladqty
                // console.log(shops2[i], scladqtyA100)
              }
              if (scladqtyA1000) {
                maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
                // console.log(shops2[i], scladqtyA1000)
              }


            }



            if (prod50 || prod100 || prod1000) {
              return index <= amount && (
                <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
                  {/* <img id={id} className={style.img} src={tt} /> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.imgcont} style={{ height: '350px' }}>
                    <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt={name}
                      height="100%"
                      effect="blur"
                    // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                    // width="600px"
                    />
                  </div>
                  <p
                    // onClick={() => GoInfo(id, name)}
                    id={id}>{name}</p>

                  {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {/* {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>} */}

                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>

                  <div style={{
                    //  margin: '5%',
                    flexWrap: 'wrap', display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'
                  }} className={style.container_price}>

                    {prod50 && Number(maximumA50) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>50гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod50.price}РУБ/{prod50.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></p>



                          </td>
                        }))}

                        {cart50 && (<><h6>Кол-во:</h6><h5> {prod50.quantidade}{prod50.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart50 && Number(maximumA50) > 0 && (<>

                            <div  className={style.doublexoom}>
 <ButtonsModal id={prod50.id} max={maximumA50} />
                        </div>
                           
                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6>

                          {cart50 && Number(maximumA50) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />

                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod50.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA50) > 0 && prod50.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod100 && Number(maximumA100) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>100гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



                          </td>
                        }))}

                        {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
                          {!cart100 && Number(maximumA100) > 0 && (<>


                            <div  className={style.doublexoom}>
                            <ButtonsModal id={prod100.id} max={maximumA100} />

                        </div>
                            {/* {' '}  <h4> {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

                          {cart100 && Number(maximumA100) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod100.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA100) > 0 && prod100.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod1000 && Number(maximumA1000) > 0.3 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>Развес</h2>
                        <h6>Цена:</h6>
                        <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



                          </td>
                        }))}
                        {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart1000 && Number(maximumA1000) > 0 && (<>

                            <div  className={style.doublexoom}>
                            <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

                        </div>
                            {/* {' '} <h4> {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

                          {cart1000 && Number(maximumA1000) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(cart1000.quantidade) * Number(cart1000.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(cart1000.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA1000) > 0 && prod1000.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
                          </div>
                        )}
                      </div>
                    )}
                    {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Распродано!
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              )
            } else return null

          }
        )}
  </div>
  </TabPanel>
<TabPanel>
        <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>

        {!opt.auth && availebleGroups.filter(pr=>pr.podkategory=='Курица').map(
          ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
            const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
            const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
            const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
            const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
            const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

            let maximumA50 = 0
            let maximumA100 = 0
            let maximumA1000 = 0
                console.log('prod50',prod50)

            for (let i = 0; i < shops2.length; i++) {
              const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
              const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
              const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
              if (scladqtyA50) {
                maximumA50 = maximumA50 + scladqtyA50.scladqty
                // console.log(shops2[i], scladqtyA50)
              }
              if (scladqtyA100) {
                maximumA100 = maximumA100 + scladqtyA100.scladqty
                // console.log(shops2[i], scladqtyA100)
              }
              if (scladqtyA1000) {
                maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
                // console.log(shops2[i], scladqtyA1000)
              }


            }



            if (prod50 || prod100 || prod1000) {
              return index <= amount && (
                <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
                  {/* <img id={id} className={style.img} src={tt} /> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.imgcont} style={{ height: '350px' }}>
                    <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt={name}
                      height="100%"
                      effect="blur"
                    // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                    // width="600px"
                    />
                  </div>
                  <p
                    // onClick={() => GoInfo(id, name)}
                    id={id}>{name}</p>

                  {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {/* {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>} */}

                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>

                  <div style={{
                    //  margin: '5%',
                    flexWrap: 'wrap', display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'
                  }} className={style.container_price}>

                    {prod50 && Number(maximumA50) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>50гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod50.price}РУБ/{prod50.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></p>



                          </td>
                        }))}

                        {cart50 && (<><h6>Кол-во:</h6><h5> {prod50.quantidade}{prod50.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart50 && Number(maximumA50) > 0 && (<>

                            <div  className={style.doublexoom}>
 <ButtonsModal id={prod50.id} max={maximumA50} />
                        </div>
                           
                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6>

                          {cart50 && Number(maximumA50) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />

                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod50.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA50) > 0 && prod50.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod100 && Number(maximumA100) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>100гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



                          </td>
                        }))}

                        {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
                          {!cart100 && Number(maximumA100) > 0 && (<>


                            <div  className={style.doublexoom}>
                            <ButtonsModal id={prod100.id} max={maximumA100} />

                        </div>
                            {/* {' '}  <h4> {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

                          {cart100 && Number(maximumA100) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod100.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA100) > 0 && prod100.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod1000 && Number(maximumA1000) > 0.3 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>Развес</h2>
                        <h6>Цена:</h6>
                        <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



                          </td>
                        }))}
                        {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart1000 && Number(maximumA1000) > 0 && (<>

                            <div  className={style.doublexoom}>
                            <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

                        </div>
                            {/* {' '} <h4> {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

                          {cart1000 && Number(maximumA1000) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(cart1000.quantidade) * Number(cart1000.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(cart1000.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA1000) > 0 && prod1000.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
                          </div>
                        )}
                      </div>
                    )}
                    {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Распродано!
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              )
            } else return null

          }
        )}
  </div>
  </TabPanel>
<TabPanel>
        <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>

        {!opt.auth && availebleGroups.filter(pr=>pr.podkategory=='Индейка').map(
          ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
            const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
            const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
            const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
            const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
            const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

            let maximumA50 = 0
            let maximumA100 = 0
            let maximumA1000 = 0
                console.log('prod50',prod50)

            for (let i = 0; i < shops2.length; i++) {
              const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
              const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
              const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
              if (scladqtyA50) {
                maximumA50 = maximumA50 + scladqtyA50.scladqty
                // console.log(shops2[i], scladqtyA50)
              }
              if (scladqtyA100) {
                maximumA100 = maximumA100 + scladqtyA100.scladqty
                // console.log(shops2[i], scladqtyA100)
              }
              if (scladqtyA1000) {
                maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
                // console.log(shops2[i], scladqtyA1000)
              }


            }



            if (prod50 || prod100 || prod1000) {
              return index <= amount && (
                <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
                  {/* <img id={id} className={style.img} src={tt} /> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.imgcont} style={{ height: '350px' }}>
                    <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt={name}
                      height="100%"
                      effect="blur"
                    // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                    // width="600px"
                    />
                  </div>
                  <p
                    // onClick={() => GoInfo(id, name)}
                    id={id}>{name}</p>

                  {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {/* {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>} */}

                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>

                  <div style={{
                    //  margin: '5%',
                    flexWrap: 'wrap', display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'
                  }} className={style.container_price}>

                    {prod50 && Number(maximumA50) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>50гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod50.price}РУБ/{prod50.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></p>



                          </td>
                        }))}

                        {cart50 && (<><h6>Кол-во:</h6><h5> {prod50.quantidade}{prod50.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart50 && Number(maximumA50) > 0 && (<>

                            <div  className={style.doublexoom}>
 <ButtonsModal id={prod50.id} max={maximumA50} />
                        </div>
                           
                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6>

                          {cart50 && Number(maximumA50) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />

                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod50.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA50) > 0 && prod50.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod100 && Number(maximumA100) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>100гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



                          </td>
                        }))}

                        {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
                          {!cart100 && Number(maximumA100) > 0 && (<>


                            <div  className={style.doublexoom}>
                            <ButtonsModal id={prod100.id} max={maximumA100} />

                        </div>
                            {/* {' '}  <h4> {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

                          {cart100 && Number(maximumA100) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod100.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA100) > 0 && prod100.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod1000 && Number(maximumA1000) > 0.3 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>Развес</h2>
                        <h6>Цена:</h6>
                        <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



                          </td>
                        }))}
                        {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart1000 && Number(maximumA1000) > 0 && (<>

                            <div  className={style.doublexoom}>
                            <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

                        </div>
                            {/* {' '} <h4> {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

                          {cart1000 && Number(maximumA1000) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(cart1000.quantidade) * Number(cart1000.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(cart1000.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA1000) > 0 && prod1000.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
                          </div>
                        )}
                      </div>
                    )}
                    {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Распродано!
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              )
            } else return null

          }
        )}
  </div>
  </TabPanel>
<TabPanel>
        <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>

        {!opt.auth && availebleGroups.filter(pr=>pr.podkategory=='Утка').map(
          ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
            const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
            const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
            const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
            const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
            const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

            let maximumA50 = 0
            let maximumA100 = 0
            let maximumA1000 = 0
                console.log('prod50',prod50)

            for (let i = 0; i < shops2.length; i++) {
              const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
              const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
              const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
              if (scladqtyA50) {
                maximumA50 = maximumA50 + scladqtyA50.scladqty
                // console.log(shops2[i], scladqtyA50)
              }
              if (scladqtyA100) {
                maximumA100 = maximumA100 + scladqtyA100.scladqty
                // console.log(shops2[i], scladqtyA100)
              }
              if (scladqtyA1000) {
                maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
                // console.log(shops2[i], scladqtyA1000)
              }


            }



            if (prod50 || prod100 || prod1000) {
              return index <= amount && (
                <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
                  {/* <img id={id} className={style.img} src={tt} /> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.imgcont} style={{ height: '350px' }}>
                    <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt={name}
                      height="100%"
                      effect="blur"
                    // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                    // width="600px"
                    />
                  </div>
                  <p
                    // onClick={() => GoInfo(id, name)}
                    id={id}>{name}</p>

                  {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {/* {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>} */}

                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>

                  <div style={{
                    //  margin: '5%',
                    flexWrap: 'wrap', display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'
                  }} className={style.container_price}>

                    {prod50 && Number(maximumA50) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>50гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod50.price}РУБ/{prod50.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></p>



                          </td>
                        }))}

                        {cart50 && (<><h6>Кол-во:</h6><h5> {prod50.quantidade}{prod50.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart50 && Number(maximumA50) > 0 && (<>

                            <div  className={style.doublexoom}>
 <ButtonsModal id={prod50.id} max={maximumA50} />
                        </div>
                           
                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6>

                          {cart50 && Number(maximumA50) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />

                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod50.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA50) > 0 && prod50.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod100 && Number(maximumA100) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>100гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



                          </td>
                        }))}

                        {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
                          {!cart100 && Number(maximumA100) > 0 && (<>


                            <div  className={style.doublexoom}>
                            <ButtonsModal id={prod100.id} max={maximumA100} />

                        </div>
                            {/* {' '}  <h4> {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

                          {cart100 && Number(maximumA100) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod100.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA100) > 0 && prod100.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod1000 && Number(maximumA1000) > 0.3 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>Развес</h2>
                        <h6>Цена:</h6>
                        <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



                          </td>
                        }))}
                        {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart1000 && Number(maximumA1000) > 0 && (<>

                            <div  className={style.doublexoom}>
                            <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

                        </div>
                            {/* {' '} <h4> {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

                          {cart1000 && Number(maximumA1000) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(cart1000.quantidade) * Number(cart1000.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(cart1000.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA1000) > 0 && prod1000.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
                          </div>
                        )}
                      </div>
                    )}
                    {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Распродано!
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              )
            } else return null

          }
        )}
  </div>
  </TabPanel>
<TabPanel>
        <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>

        {!opt.auth && availebleGroups.filter(pr=>pr.podkategory=='Кролик').map(
          ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
            const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
            const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
            const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
            const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
            const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

            let maximumA50 = 0
            let maximumA100 = 0
            let maximumA1000 = 0
                console.log('prod50',prod50)

            for (let i = 0; i < shops2.length; i++) {
              const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
              const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
              const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
              if (scladqtyA50) {
                maximumA50 = maximumA50 + scladqtyA50.scladqty
                // console.log(shops2[i], scladqtyA50)
              }
              if (scladqtyA100) {
                maximumA100 = maximumA100 + scladqtyA100.scladqty
                // console.log(shops2[i], scladqtyA100)
              }
              if (scladqtyA1000) {
                maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
                // console.log(shops2[i], scladqtyA1000)
              }


            }



            if (prod50 || prod100 || prod1000) {
              return index <= amount && (
                <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
                  {/* <img id={id} className={style.img} src={tt} /> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.imgcont} style={{ height: '350px' }}>
                    <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt={name}
                      height="100%"
                      effect="blur"
                    // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                    // width="600px"
                    />
                  </div>
                  <p
                    // onClick={() => GoInfo(id, name)}
                    id={id}>{name}</p>

                  {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {/* {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>} */}

                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>

                  <div style={{
                    //  margin: '5%',
                    flexWrap: 'wrap', display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'
                  }} className={style.container_price}>

                    {prod50 && Number(maximumA50) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>50гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod50.price}РУБ/{prod50.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></p>



                          </td>
                        }))}

                        {cart50 && (<><h6>Кол-во:</h6><h5> {prod50.quantidade}{prod50.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart50 && Number(maximumA50) > 0 && (<>

                            <div  className={style.doublexoom}>
 <ButtonsModal id={prod50.id} max={maximumA50} />
                        </div>
                           
                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6>

                          {cart50 && Number(maximumA50) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />

                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod50.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA50) > 0 && prod50.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod100 && Number(maximumA100) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>100гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



                          </td>
                        }))}

                        {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
                          {!cart100 && Number(maximumA100) > 0 && (<>


                            <div  className={style.doublexoom}>
                            <ButtonsModal id={prod100.id} max={maximumA100} />

                        </div>
                            {/* {' '}  <h4> {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

                          {cart100 && Number(maximumA100) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod100.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA100) > 0 && prod100.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod1000 && Number(maximumA1000) > 0.3 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>Развес</h2>
                        <h6>Цена:</h6>
                        <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



                          </td>
                        }))}
                        {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart1000 && Number(maximumA1000) > 0 && (<>

                            <div  className={style.doublexoom}>
                            <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

                        </div>
                            {/* {' '} <h4> {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

                          {cart1000 && Number(maximumA1000) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(cart1000.quantidade) * Number(cart1000.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(cart1000.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA1000) > 0 && prod1000.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
                          </div>
                        )}
                      </div>
                    )}
                    {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Распродано!
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              )
            } else return null

          }
        )}
  </div>
  </TabPanel>
<TabPanel>
        <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>

        {!opt.auth && availebleGroups.filter(pr=>pr.podkategory=='Рыба').map(
          ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
            const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
            const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
            const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
            const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
            const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

            let maximumA50 = 0
            let maximumA100 = 0
            let maximumA1000 = 0
                console.log('prod50',prod50)

            for (let i = 0; i < shops2.length; i++) {
              const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
              const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
              const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
              if (scladqtyA50) {
                maximumA50 = maximumA50 + scladqtyA50.scladqty
                // console.log(shops2[i], scladqtyA50)
              }
              if (scladqtyA100) {
                maximumA100 = maximumA100 + scladqtyA100.scladqty
                // console.log(shops2[i], scladqtyA100)
              }
              if (scladqtyA1000) {
                maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
                // console.log(shops2[i], scladqtyA1000)
              }


            }



            if (prod50 || prod100 || prod1000) {
              return index <= amount && (
                <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
                  {/* <img id={id} className={style.img} src={tt} /> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.imgcont} style={{ height: '350px' }}>
                    <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt={name}
                      height="100%"
                      effect="blur"
                    // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                    // width="600px"
                    />
                  </div>
                  <p
                    // onClick={() => GoInfo(id, name)}
                    id={id}>{name}</p>

                  {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                  <div
                    // onClick={() => GoInfo(id, name)} 
                    className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {/* {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>} */}

                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>

                  <div style={{
                    //  margin: '5%',
                    flexWrap: 'wrap', display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'
                  }} className={style.container_price}>

                    {prod50 && Number(maximumA50) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>50гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod50.price}РУБ/{prod50.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></p>



                          </td>
                        }))}

                        {cart50 && (<><h6>Кол-во:</h6><h5> {prod50.quantidade}{prod50.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart50 && Number(maximumA50) > 0 && (<>

                            <div  className={style.doublexoom}>
 <ButtonsModal id={prod50.id} max={maximumA50} />
                        </div>
                           
                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6>

                          {cart50 && Number(maximumA50) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />

                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod50.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA50) > 0 && prod50.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod100 && Number(maximumA100) > 0 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>100гр</h2>
                        <h6>Цена:</h6>
                        <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



                          </td>
                        }))}

                        {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
                          {!cart100 && Number(maximumA100) > 0 && (<>


                            <div  className={style.doublexoom}>
                            <ButtonsModal id={prod100.id} max={maximumA100} />

                        </div>
                            {/* {' '}  <h4> {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

                          {cart100 && Number(maximumA100) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(prod100.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA100) > 0 && prod100.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
                          </div>
                        )}
                      </div>
                    )}
                    {prod1000 && Number(maximumA1000) > 0.3 && (
                      <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
                        <h2>Развес</h2>
                        <h6>Цена:</h6>
                        <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
                        {shops2.length > 0 && (shops2.map(pr => {
                          const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
                          // console.log(productDots, scladqty)
                          return <td className={style.tabletd}>

                            <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



                          </td>
                        }))}
                        {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

                          {!cart1000 && Number(maximumA1000) > 0 && (<>

                            <div  className={style.doublexoom}>
                            <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

                        </div>
                            {/* {' '} <h4> {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h4> */}

                            <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
                          </>
                          )}
                          <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

                          {cart1000 && Number(maximumA1000) > 0 && (<>

                            <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
                            {/* <h4>
                            {(Number(cart1000.quantidade) * Number(cart1000.price)).toFixed(2)}РУБ</h4> */}
                            <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(cart1000.id)} />
                          </>
                          )}
                        </div>
                        {Number(maximumA1000) > 0 && prod1000.donation && (
                          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                            <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
                          </div>
                        )}
                      </div>
                    )}
                    {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Распродано!
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              )
            } else return null

          }
        )}
  </div>
  </TabPanel>
    </Tabs >

      {donatePriutModal && (
        <ModalDonatePriuts
          id={donInfo.id}
          maxumum={donInfo.maximum}
        // setModal={setModal}
        // modal={modal}
        />
      )}
    </>
  );
};

export default ProdutosDry;
// import React, { useEffect, useContext, useState } from "react";
// import { produtos } from "./JsonProdutosDry";
// import style from "./Produtos.module.css";
// import Modal from "../Modal/Modal";
// import { QtdContext } from "../../context/qtdContext";
// import { useNavigate } from "react-router-dom";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
// import Button from "../Button/Button";
// import ButtonsModalVes from "../Modal/ButtonsModal/ButtonsModalVes";
// import ModalDonatePriuts from "../Pages/DashBoard/Components/Modal/ModalDonatePriuts";

// const ProdutosDry = ({ amount }) => {
//   // const “ = JSON.parse(localStorage.getItem("products"));
//   const { shops, setShops, donatePriutModal, setDonatePriutModal, productDots, productGroups, setproductGroups, serverProd, produtosSelecionados, opt, tabs, setTabs, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
//   const navigate = useNavigate()
//   const drydey = serverProd.filter((prod) => prod.kategory.includes("сушенная продукция"))
//   // const drydey = nosorted.sort((a, b) => a.name.localeCompare(b.name));
//   const shops2 = shops.filter(pr => pr.showed)
//   // console.log(drydey)
//   const [donInfo, setDonInfo] = useState({})

//   const Donate = (id, maxumum) => {
//     setDonInfo({ id, maxumum })
//     setDonatePriutModal(true)
//   };
//   const removeItem = async (itemId) => {
//     const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
//     setProdutosSelecionados(prodRemove)
//     localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

//   }
//   const changeItensCart = (id) => {
//     const produtos = serverProd

//     const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
//     const produtoDados = produtos.find((produto) => produto.id == +id);



//     const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
//     const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
//     const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
//     // console.log(novosProdutos, produto)
//     setProdutosSelecionados(novosProdutos);
//     localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

//     // console.log(produtosNaoSelecionados, produtosSelecionados)
//     // setModal(false);
//     // console.log('novosProdutos')
//   }
//   const GoInfo = (imageId, name) => {
//     navigate(`/prodinfo/${imageId}`, {
//       state: {
//         serverProd: serverProd,

//         produtosSelecionados: produtosSelecionados,
//         name: name,
//         imageId: imageId,
//         loca: "/lacomstva"

//       }
//     });
//   };
//   const availebleGroups = productGroups.sort((a, b) => {
//     const aprod50 = serverProd.find((produto) => produto.shcode == a.sh50)
//     const aprod100 = serverProd.find((produto) => produto.shcode == a.sh100)
//     const aprod1000 = serverProd.find((produto) => produto.shcode == a.sh1000)

//     const bprod50 = serverProd.find((produto) => produto.shcode == b.sh50)
//     const bprod100 = serverProd.find((produto) => produto.shcode == b.sh100)
//     const bprod1000 = serverProd.find((produto) => produto.shcode == b.sh1000)

//     let maximumA50 = 0
//     let maximumA100 = 0
//     let maximumA1000 = 0
//     let maximumB50 = 0
//     let maximumB100 = 0
//     let maximumB1000 = 0
//     for (let i = 0; i < shops2.length; i++) {
//       const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh50 && pd.prod_id == aprod50.id && shops2[i].id == pd.shop_id)
//       const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh100 && pd.prod_id == aprod100.id && shops2[i].id == pd.shop_id)
//       const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh1000 && pd.prod_id == aprod1000.id && shops2[i].id == pd.shop_id)
//       if (scladqtyA50) {
//         maximumA50 = maximumA50 + scladqtyA50.scladqty
//         // console.log(shops2[i], scladqtyA50)
//       }
//       if (scladqtyA100) {
//         maximumA100 = maximumA100 + scladqtyA100.scladqty
//         // console.log(shops2[i], scladqtyA100)
//       }
//       if (scladqtyA1000) {
//         maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
//         // console.log(shops2[i], scladqtyA1000)
//       }

//       const scladqtyB50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh50 && pd.prod_id == bprod50 ? bprod50.id : false && shops2[i].id == pd.shop_id)
//       const scladqtyB100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh100 && pd.prod_id == bprod100 ? bprod100.id : false && shops2[i].id == pd.shop_id)
//       const scladqtyB1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.sh1000 && pd.prod_id == bprod1000 ? bprod1000.id : false && shops2[i].id == pd.shop_id)


//       if (scladqtyB50) {
//         maximumB50 = maximumB50 + scladqtyB50.scladqty
//         // console.log(shops2[i], scladqtyB50)
//       }
//       if (scladqtyB100) {
//         maximumB100 = maximumB100 + scladqtyB100.scladqty
//         // console.log(shops2[i], scladqtyB100)
//       }
//       if (scladqtyB1000) {
//         maximumB1000 = maximumB1000 + scladqtyB1000.scladqty
//         // console.log(shops2[i], scladqtyB1000)
//       }
//     }

//     // if (( maximumA50 > 0  ||  maximumA100 > 0 ||  maximumA1000 > 0) && ( maximumB50 === 0 ||  maximumB100 === 0 ||  maximumB1000 === 0)) return -1;
//     // if (( maximumB50 === 0 &&  maximumB100 === 0 &&  maximumB1000 === 0)) return 1;

//     // Если оба объекта в одной группе, сортируем по name
//     return a.name.localeCompare(b.name);
//   });

//   return (
//     <>
//       <br />
//       <div className={style.borders}>
//         <br />
//         <p className={style.p}>
//           Представляем вашему вниманию вкуснейшие сушеные лакомства для собак всех пород и размеров!
//         </p>
//         <br />

//         <p className={style.p}>
//           Наши лакомства (вкусняшки) 100% натуральные, без пищевых добавок, красителей и ароматизаторов. Мы используем сырье только местных фермерских хозяйств, где животные имеют ветеринарные свидетельства, а бойни сертифицированы.
//         </p>
//         <br />
//         <p className={style.p}>
//           Невероятно аппетитный аромат мясных (говяжьих), индюшачих и рыбных лакомств, не оставит равнодушными ваших питомцев. Используемые виды мяса подойдут собакам с аллергией на курицу. Ассортимент ошеломит любого хозяина и влюбит в себя каждого питомца. В составе только 100% мясо, субпродукты и мясо-костные части, прошедшие через специальную технологию сушки. Наши лакомства выбирают многие заводчики, грумеры, кинологи и простые собаководы за натуральность, свежесть, многообразие ассортимента и невозможность оторвать питомца от заветных пакетиков. Побалуйте своего любимого питомца!
//         </p>
//         <br />
//       </div>
//       <br />
//       <div className={style.produtos_containersuh} style={{ alignItems: 'baseline' }}>
//         {!opt.auth && availebleGroups.map(
//           ({ photo, name, id, forwho, sh50, sh100, sh1000 }, index) => {
//             let tt = ''
//             if (photo[0]) {
//               tt = photo[0]
//             }
//             const prod50 = serverProd.find((produto) => produto.shcode == sh50) || { scladqty: 0 };
//             const prod100 = serverProd.find((produto) => produto.shcode == sh100) || { scladqty: 0 };
//             const prod1000 = serverProd.find((produto) => produto.shcode == sh1000) || { scladqty: 0 };
//             const cart50 = produtosSelecionados.find((produto) => produto.id == prod50.id);
//             const cart100 = produtosSelecionados.find((produto) => produto.id == prod100.id);
//             const cart1000 = produtosSelecionados.find((produto) => produto.id == prod1000.id);

//             let maximumA50 = 0
//             let maximumA100 = 0
//             let maximumA1000 = 0
//             console.log('prod50', prod50)

//             for (let i = 0; i < shops2.length; i++) {
//               const scladqtyA50 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && shops2[i].id == pd.shop_id)
//               const scladqtyA100 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && shops2[i].id == pd.shop_id)
//               const scladqtyA1000 = productDots.find(pd => shops2[i].showed && pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && shops2[i].id == pd.shop_id)
//               if (scladqtyA50) {
//                 maximumA50 = maximumA50 + scladqtyA50.scladqty
//                 // console.log(shops2[i], scladqtyA50)
//               }
//               if (scladqtyA100) {
//                 maximumA100 = maximumA100 + scladqtyA100.scladqty
//                 // console.log(shops2[i], scladqtyA100)
//               }
//               if (scladqtyA1000) {
//                 maximumA1000 = maximumA1000 + scladqtyA1000.scladqty
//                 // console.log(shops2[i], scladqtyA1000)
//               }


//             }



//             if (prod50 || prod100 || prod1000) {
//               return index <= amount && (
//                 <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximumA50 == 0 && maximumA100 == 0 && maximumA1000 == 0 ? "0.5" : "1", height: '100%' }} >
//                   {/* <img id={id} className={style.img} src={tt} /> */}
//                   <div
//                     // onClick={() => GoInfo(id, name)} 
//                     className={style.imgcont} style={{ width: '80%', height:'350px', objectFit:'contain' }}>
//                     <LazyLoadImage
//                       id={id}
//                       className={style.img}
//                       src={tt}
//                       alt={name}
//                       height="100%"
//                       effect="blur"
//                     // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
//                     // width="600px"
//                     />
//                   </div>
//                   <h1
//                     // onClick={() => GoInfo(id, name)}
//                     id={id}>{name}</h1>


//                   <div style={{
//                     //  margin: '5%',
//                     flexWrap: 'wrap', display: 'flex', flexDirection: "column", width: '100%', justifyContent: 'space-evenly'
//                   }} className={style.container_price}>

//                     {prod50 && Number(maximumA50) > 0 && (
//                       <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '94%', justifyContent: 'space-between' }} className={style.container_price}>
//                         {!cart50 && Number(maximumA50) > 0 && (<>
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
//                             <h2>50гр x {prod50.price}РУБ/{prod50.mnt}</h2>



//                             <div className={style.doublexoom}>
//                               <ButtonsModal id={prod50.id} max={maximumA50} />
//                             </div>

//                             <h2>
//                               {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ
//                             </h2>
//                           </div>
//                         </>)}
//                         {!cart50 && Number(maximumA50) > 0 && (<>
//                           <Button width={'100%'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod50.id)} />
//                         </>
//                         )}

//                         {cart50 && (<><h2>50гр x {prod50.price}РУБ/{prod50.mnt} x {prod50.quantidade}{prod50.mnt} = {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h2></>)}

//                         <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>


//                           {/* <h5>Сумма:</h5><h6>  {(Number(prod50.quantidade) * Number(prod50.price)).toFixed(2)}РУБ</h6> */}

//                           {cart50 && Number(maximumA50) > 0 && (<>

//                             <Button width='40%' text="В Корзину" onClick={() => setModalCart(true)} />

//                             <Button width='40%' text="Удалить из Корзины " onClick={() => removeItem(prod50.id)} />
//                           </>
//                           )}
//                         </div>
//                         {Number(maximumA50) > 0 && prod50.donation && (
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'center' }} className={style.container_price}>

//                             <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod50.id, maximumA50)} />
//                           </div>
//                         )}
//                         {/* <h6>Цена:</h6> */}
//                         {/* <h5>{prod50.price}РУБ/{prod50.mnt}</h5> */}
//                         {shops2.length > 0 && (shops2.map(pr => {
//                           const scladqty = productDots.find(pd => pd.prod_sh == prod50.shcode && pd.prod_id == prod50.id && pr.id == pd.shop_id)
//                           // console.log(productDots, scladqty)
//                           return <td className={style.tabletd}>

//                             <h2 style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod50.mnt}</b></h2>



//                           </td>
//                         }))}




//                       </div>
//                     )}
//                      {prod100 && Number(maximumA100) > 0 && (
//                       <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '94%', justifyContent: 'space-between' }} className={style.container_price}>
//                         {!cart100 && Number(maximumA100) > 0 && (<>
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
//                             <h2>100гр x {prod100.price}РУБ/{prod100.mnt}</h2>



//                             <div className={style.doublexoom}>
//                               <ButtonsModal id={prod100.id} max={maximumA100} />
//                             </div>

//                             <h2>
//                               {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ
//                             </h2>
//                           </div>
//                         </>)}
//                         {!cart100 && Number(maximumA100) > 0 && (<>
//                           <Button width={'100%'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
//                         </>
//                         )}

//                         {cart100 && (<><h2>100гр x {prod100.price}РУБ/{prod100.mnt} x {prod100.quantidade}{prod100.mnt} = {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h2></>)}

//                         <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>


//                           {/* <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6> */}

//                           {cart100 && Number(maximumA100) > 0 && (<>

//                             <Button width='40%' text="В Корзину" onClick={() => setModalCart(true)} />

//                             <Button width='40%' text="Удалить из Корзины " onClick={() => removeItem(prod100.id)} />
//                           </>
//                           )}
//                         </div>
//                         {Number(maximumA100) > 0 && prod100.donation && (
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'center' }} className={style.container_price}>

//                             <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
//                           </div>
//                         )}
//                         {/* <h6>Цена:</h6> */}
//                         {/* <h5>{prod100.price}РУБ/{prod100.mnt}</h5> */}
//                         {shops2.length > 0 && (shops2.map(pr => {
//                           const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
//                           // console.log(productDots, scladqty)
//                           return <td className={style.tabletd}>

//                             <h2 style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></h2>



//                           </td>
//                         }))}




//                       </div>
//                     )}
//                       {prod1000 && Number(maximumA1000) > 0 && (
//                       <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '94%', justifyContent: 'space-between' }} className={style.container_price}>
//                         {!cart1000 && Number(maximumA1000) > 0 && (<>
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
//                             <h2>1000гр x {prod1000.price}РУБ/{prod1000.mnt}</h2>



//                             <div className={style.doublexoom}>
//                               <ButtonsModalVes id={prod1000.id} max={maximumA1000} />
//                             </div>

//                             <h2>
//                               {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ
//                             </h2>
//                           </div>
//                         </>)}
//                         {!cart1000 && Number(maximumA1000) > 0 && (<>
//                           <Button width={'100%'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
//                         </>
//                         )}

//                         {cart1000 && (<><h2>1000гр x {prod1000.price}РУБ/{prod1000.mnt} x {prod1000.quantidade}{prod1000.mnt} = {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h2></>)}

//                         <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'space-between' }} className={style.container_price}>


//                           {/* <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6> */}

//                           {cart1000 && Number(maximumA1000) > 0 && (<>

//                             <Button width='40%' text="В Корзину" onClick={() => setModalCart(true)} />

//                             <Button width='40%' text="Удалить из Корзины " onClick={() => removeItem(prod1000.id)} />
//                           </>
//                           )}
//                         </div>
//                         {Number(maximumA1000) > 0 && prod1000.donation && (
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'center' }} className={style.container_price}>

//                             <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
//                           </div>
//                         )}
//                         {/* <h6>Цена:</h6> */}
//                         {/* <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5> */}
//                         {shops2.length > 0 && (shops2.map(pr => {
//                           const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
//                           // console.log(productDots, scladqty)
//                           return <td className={style.tabletd}>

//                             <h2 style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></h2>



//                           </td>
//                         }))}




//                       </div>
//                     )}
//                     {/* {prod100 && Number(maximumA100) > 0 && (
//                       <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
//                         <h2>100гр</h2>
//                         <h6>Цена:</h6>
//                         <h5>{prod100.price}РУБ/{prod100.mnt}</h5>
//                         {shops2.length > 0 && (shops2.map(pr => {
//                           const scladqty = productDots.find(pd => pd.prod_sh == prod100.shcode && pd.prod_id == prod100.id && pr.id == pd.shop_id)
//                           return <td className={style.tabletd}>

//                             <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod100.mnt}</b></p>



//                           </td>
//                         }))}

//                         {cart100 && (<><h6>Кол-во:</h6><h5> {prod100.quantidade}{prod100.mnt}</h5></>)}


//                         <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>
//                           {!cart100 && Number(maximumA100) > 0 && (<>


//                             <div className={style.doublexoom}>
//                               <ButtonsModal id={prod100.id} max={maximumA100} />

//                             </div>

//                             <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod100.id)} />
//                           </>
//                           )}
//                           <h5>Сумма:</h5><h6>  {(Number(prod100.quantidade) * Number(prod100.price)).toFixed(2)}РУБ</h6>

//                           {cart100 && Number(maximumA100) > 0 && (<>

//                             <Button width='100%' text="В Корзину" onClick={() => setModalCart(true)} />
//                             <Button width='100%' text="Удалить из Корзины " onClick={() => removeItem(prod100.id)} />
//                           </>
//                           )}
//                         </div>
//                         {Number(maximumA100) > 0 && prod100.donation && (
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

//                             <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod100.id, maximumA100)} />
//                           </div>
//                         )}
//                       </div>
//                     )}
//                     {prod1000 && Number(maximumA1000) > 0.3 && (
//                       <div style={{ margin: '3%', display: 'flex', flexDirection: "column", alignItems: 'center', width: '25%', justifyContent: 'space-between' }} className={style.container_price}>
//                         <h2>Развес</h2>
//                         <h6>Цена:</h6>
//                         <h5>{prod1000.price}РУБ/{prod1000.mnt}</h5>
//                         {shops2.length > 0 && (shops2.map(pr => {
//                           const scladqty = productDots.find(pd => pd.prod_sh == prod1000.shcode && pd.prod_id == prod1000.id && pr.id == pd.shop_id)
//                           return <td className={style.tabletd}>

//                             <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{prod1000.mnt}</b></p>



//                           </td>
//                         }))}
//                         {cart1000 && (<><h6>Кол-во:</h6><h5> {prod1000.quantidade}{prod1000.mnt}</h5></>)}


//                         <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: '85%', justifyContent: 'space-between' }} className={style.container_price}>

//                           {!cart1000 && Number(maximumA1000) > 0 && (<>

//                             <div className={style.doublexoom}>
//                               <ButtonsModalVes id={prod1000.id} max={maximumA1000} />

//                             </div>

//                             <Button width={'100px'} style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(prod1000.id)} />
//                           </>
//                           )}
//                           <h5>Сумма:</h5><h6>  {(Number(prod1000.quantidade) * Number(prod1000.price)).toFixed(2)}РУБ</h6>

//                           {cart1000 && Number(maximumA1000) > 0 && (<>

//                             <Button width='100%' text="В Корзину" onClick={() => setModalCart(true)} />
//                             <Button width='100%' text="Удалить из Корзины " onClick={() => removeItem(cart1000.id)} />
//                           </>
//                           )}
//                         </div>
//                         {Number(maximumA1000) > 0 && prod1000.donation && (
//                           <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

//                             <Button width='100%' text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(prod1000.id, maximumA1000)} />
//                           </div>
//                         )}
//                       </div>
//                     )} */}
//                     {Number(maximumA50) < 1 && Number(maximumA100.scladqty) < 1 && Number(maximumA1000.scladqty) < 0.3 && (
//                       <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                         Распродано!
//                         <span></span>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               )
//             } else return null

//           }
//         )}
//         {/* {!opt.auth && drydey.map(
//           ({ photo, name, price, id, forwho, scladqty }, index) => {
//             let tt = ''
//             if (photo[0]) {
//               tt = photo[0]
//             }
//             const cart = produtosSelecionados.find((produto) => produto.id == +id);
//             let cartb = false
//             if (cart) {
//               cartb = true
//             }
//             return index <= amount && (
//               <div key={id} id={id} className={style.cards_produtos}>
//                 <div onClick={() => GoInfo(id, name)} className={style.imgcont}>
//                   <LazyLoadImage
//                     id={id}
//                     className={style.img}
//                     src={tt}
//                     alt="Example Image"
//                     height="100%"
//                     effect="blur"
               
//                   />
//                 </div>
//                 <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
//                 <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
//                   {forwho == 'Для собак' && (
//                     <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                       🐶<span></span>
//                     </div>
//                   )}
//                   {forwho == 'Для кошек' && (
//                     <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                       🐱<span></span>
//                     </div>
//                   )}
//                   {name == 'Мясные консервы «говядина с потрошками» 525г' && (
//                     <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                       ХИТ
//                       <span></span>
//                     </div>
//                   )}
//                   {Number(scladqty) > 0 &&
//                     <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                       {price}RUB
//                       <span></span>
//                     </div>}
//                   {Number(scladqty) <= 0 &&
//                     <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                       Распродано!
//                       <span></span>
//                     </div>}

//                   <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                     Натуральный
//                     <span></span>
//                   </div>
//                 </div>
//                 <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
//                   {!cartb && Number(scladqty) > 0 && (<>


//                     <ButtonsModal id={id} />
                   
//                     <Button style={{ height: '100px', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(id)} />
//                   </>
//                   )}
//                   {cartb && Number(scladqty) > 0 && (<>
//                     <Button  width='100%'text="В Корзину"  onClick={() => setModalCart(true)} />
//                     <Button  width='100%'text="Удалить из Корзины "  onClick={() => removeItem(id)} />
//                   </>
//                   )}
//                 </div>
//               </div>
//             )
//           }
//         )} */}
//         {opt.auth && drydey.map(
//           ({ photo, name, vipprice, optprice, id, scladqty }, index) => {
//             let tt = ''
//             if (photo[0]) {
//               tt = photo[0]
//             }
//             const cart = produtosSelecionados.find((produto) => produto.id == +id);
//             let cartb = false
//             if (cart) {
//               cartb = true
//             }
//             return index <= amount && (
//               <div key={id} id={id} className={style.cards_produtos}>
//                 <div>
//                   {/* <img id={id} className={style.img} src={tt} /> */}
//                   <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
//                     <LazyLoadImage
//                       id={id}
//                       className={style.img}
//                       src={tt}
//                       alt="Example Image"
//                       height="100%"
//                       effect="blur"
//                     // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
//                     // width="600px"
//                     />
//                   </div>
//                 </div>
//                 <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
//                 {/* <p id={id} className={style.price}><b>{optprice}RUB</b></p> */}
//                 <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
//                   {(name == 'Печеньки говяжьи с овощами 50г' || name == 'Вымя говяжье 50г' || name == "Бычий Корень 50г" || name == "Рубец Кубики 50г") && (
//                     <div href="#" className={style.vbadge} style={{ 'background': '#04b31b', 'color': 'black' }}>
//                       ХИТ
//                       <span></span>
//                     </div>
//                   )}
//                   {(name == 'Почки Говяжьи 50г' || name == 'Ухо Внутреннее Говяжье 50г' || name == 'Трахея Ломтики 50г') && (

//                     <div href="#" className={style.vbadge}>
//                       NEW
//                       <span></span>
//                     </div>
//                   )}
//                   {vipprice.find((vp) => vp.optuserid == opt.user.id) && (vipprice.find((vp) => vp.optuserid == opt.user.id)).vipprice > 0 ? (
//                     <div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
//                       {console.log((vipprice.find((vp) => vp.optuserid == opt.user.id)))}
//                       {(vipprice.find((vp) => vp.optuserid == opt.user.id)
//                       ).vipprice}RUB
//                       <span></span>
//                     </div>
//                   ) :

//                     (<div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
//                       {optprice}RUB
//                       <span></span>
//                     </div>)
//                   }

//                   <div href="#" className={style.vbadge} style={{ 'background': '#20b6e8' }}>
//                     Натуральный
//                     <span></span>
//                   </div>
//                 </div>
//                 <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
//                   {!cartb && Number(scladqty) > 0 && (<>


//                     <ButtonsModal id={id} />
//                     <Button style={{ height: '100px', margin: 0, marginLeft: '5px' }} text="Добавить" onClick={() => changeItensCart(id)} />
//                   </>
//                   )}
//                   {cartb && Number(scladqty) > 0 && (<>
//                     <Button width='100%' text="В Корзину" onClick={() => setModalCart(true)} />
//                     <Button width='100%' text="Удалить из Корзины " onClick={() => removeItem(id)} />
//                   </>
//                   )}
//                 </div>
//               </div>
//             )
//           }
//         )}
//       </div>
//       {donatePriutModal && (
//         <ModalDonatePriuts
//           id={donInfo.id}
//           maxumum={donInfo.maximum}
//         // setModal={setModal}
//         // modal={modal}
//         />
//       )}
//     </>
//   );
// };

// export default ProdutosDry;
